import { Alert, AlertIcon, AlertDescription, CloseButton, Box, Spacer } from '@chakra-ui/react';
import { AlertMessageProps } from '../utils/types/formValues.type';

function AlertMessage({
  alertMessage,
  alertType,
  isOpen,
  onClose,
  displayAlert,
}: AlertMessageProps) {
  return isOpen ? (
    <Alert status={alertType} display={displayAlert} mb="5">
      <AlertIcon />
      <Box>
        <AlertDescription fontWeight="400" lineHeight="24px" textColor="gray.700" fontSize="14px">
          {alertMessage}
        </AlertDescription>
      </Box>
      <Spacer />
      <CloseButton alignSelf="center" position="relative" right={-1} onClick={onClose} />
    </Alert>
  ) : (
    <div />
  );
}

export default AlertMessage;
