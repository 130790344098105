import { AxiosResponse } from 'axios';
import UIStrings, { NavigationWorkFlowEnum } from '../constants/uIStrings';
import { ApiResponse } from '../types/apiResponse.type';
import { handleGlobalUrlRedirection, navigateToFed, navigateToUrl } from './navigator';
import { setCookieValue } from './persist';

export const handleSuccessfulLoginAndRedirect = (
  loginResponse: ApiResponse | AxiosResponse,
  rememberMe: string,
) => {
  const { globalURL, privateGdnUrl, jwt, age, isStatusEnabled, isDnsInfoFound } =
    loginResponse.data;
  //TODO revisit this approach of setting cookies, wether this is right or we need to tackle it from the backend.
  setCookieValue(UIStrings.SESSION_CONSTANTS.ACCESS_TOKEN, jwt, age);
  setCookieValue(UIStrings.SESSION_CONSTANTS.REMEMBER_ME, rememberMe, age);

  switch (true) {
    case checkUrlState(privateGdnUrl.trim()):
      return NavigationWorkFlowEnum.PRIVATE_GDN;

    case checkUrlState(globalURL.trim()):
      handleGlobalUrlRedirection(globalURL.trim(), isDnsInfoFound, isStatusEnabled);
      break;

    default:
      navigateToFed(true);
  }
};

const checkUrlState = (url: string): boolean => {
  if (url) {
    return true;
  }
  return false;
};
