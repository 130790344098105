const SignupLeftInformation = [
  {
    heading: 'Get started today - no credit card required',
    subHeading:
      'Instant access to our developer playground. Start from scratch or explore our library of sample apps.',
  },
  {
    heading: 'Run your apps faster, everywhere',
    subHeading: 'Apps and data in <50ms to more than 2B humans and 100B things anywhere on Earth.',
  },
  {
    heading: 'All your data (safely) in one place',
    subHeading:
      "We're SOC 2 Type 2 certified and committed to providing the industry's best security and privacy.",
  },
];

export default SignupLeftInformation;
