import { Heading, Stack, Text, Box } from '@chakra-ui/react';
import axios from 'axios';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AccountCreation from '../components/AccountCreation';
import AlertMessage from '../components/AlertMessage';
import Background from '../components/Background';
import CreateUserForm from '../components/CreateUserForm';
import LinkExpired from '../components/LinkExpired';
import Loader from '../components/Loader';
import useApi from '../hooks/useApi';
import SignUpMaintenanceContext from '../hooks/useSignUpMaintenanceContext';
import ApiCallMethods from '../utils/constants/api';
import ApiEndPoints from '../utils/constants/endPoints';
import NavigationPages from '../utils/constants/NavigationPages';
import UIStrings from '../utils/constants/uIStrings';
import { handleGlobalUrlRedirection, navigateToFed } from '../utils/helper/navigator';
import fetchUsingAxios from '../utils/service/api';
import { UserInfo } from '../utils/types/CreateUser.types';
import { SignUpMaintenanceContextType } from '../utils/types/maintenanceContext.type';

export default function CreateUser() {
  const { isPrivateGdn } = useContext<SignUpMaintenanceContextType>(SignUpMaintenanceContext);

  const { invitationId } = useParams<{ invitationId: string }>();
  const { response, getApiResult } = useApi();
  const [isLoading, setIsLoading] = useState(true);
  const [isAccountCreating, setIsAccountCreating] = useState(false);
  const [privateGdn, setPrivateGdn] = useState<boolean | undefined>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  const getInvitationDetails = useCallback(async () => {
    await getApiResult(ApiCallMethods.GET, `${ApiEndPoints.INVITE_USER}/${invitationId}`);
    setIsLoading(false);
  }, [getApiResult, invitationId]);

  const CreateUserAccount = useCallback(
    async ({ password, displayName }: UserInfo) => {
      try {
        setIsAccountCreating(true);
        const resp = await fetchUsingAxios(ApiCallMethods.POST, `${ApiEndPoints.CREATE_USER}`, {
          password,
          invitationId,
          displayName,
        });
        await getTenantDetailsAndNavigate(resp?.data.tenant);
      } catch (error: any) {
        setIsAccountCreating(false);
        setErrorMessage(error.response.data.message);
      }
    },
    [invitationId],
  );

  const getTenantDetailsAndNavigate = async (tenant: string) => {
    try {
      const tenantDetails = await fetchUsingAxios(
        ApiCallMethods.GET,
        `${ApiEndPoints.TENANT_DETAILS}?tenant=${tenant}`,
      );
      if (tenantDetails) {
        const { isDnsInfoFound, isStatusEnabled, globalURL } = tenantDetails.data;
        handleGlobalUrlRedirection(globalURL, isDnsInfoFound, isStatusEnabled);
        return;
      }
      navigateToFed(true);
    } catch (error) {
      navigateToFed(true);
    }
  };

  useEffect(() => {
    setPrivateGdn(isPrivateGdn);
  }, [isPrivateGdn]);

  useEffect(() => {
    getInvitationDetails();
  }, [getInvitationDetails]);

  useEffect(() => {
    if (response?.status === 404 || response?.status === 500) {
      history.replace(NavigationPages.ERROR);
    }
  }, [history, response]);

  if (isAccountCreating) {
    return <AccountCreation />;
  }

  if (isLoading) {
    return <Loader />;
  }

  if (response?.status === 410) {
    return <LinkExpired />;
  }

  return (
    <Background
      showButton={!privateGdn}
      headerLabel={UIStrings.BACKGROUND_HEADER}
      buttonText={UIStrings.BUTTON_TEXT.SIGN_UP}
      navigationLink={NavigationPages.SIGN_UP}
    >
      <Stack
        spacing={6}
        w={['', 'full']}
        maxW="md"
        bg="white"
        boxShadow="lg"
        borderRadius="12px"
        p={6}
        mb={['20', '0']}
        mt={['40', '60']}
      >
        <Box rounded="lg" maxW="md" bg="white" p={8}>
          <Stack align="start" pb={5} pt={3}>
            <Heading fontSize="2.5xl" fontWeight="bold">
              Sign up
            </Heading>
          </Stack>
          <Text w="100%" fontSize="14px" mb={4}>
            Accept your invite from{' '}
            <Text fontWeight="600" as="span">
              {response?.data.invitedBy}.
            </Text>
          </Text>
          {errorMessage.length > 0 && (
            <AlertMessage
              alertType="error"
              alertMessage={errorMessage}
              displayAlert="flex"
              onClose={() => setErrorMessage('')}
              isOpen={errorMessage.length > 0}
            />
          )}
          <CreateUserForm getUserInfo={CreateUserAccount} email={response?.data.inviteeEmail} />
        </Box>
      </Stack>
    </Background>
  );
}
