import { Stack, Spinner, Button, Text } from '@chakra-ui/react';
import UIStrings from '../utils/constants/uIStrings';
import Background from './Background';

export default function AccountCreation() {
  return (
    <Background showButton={false}>
      <Stack
        spacing={10}
        w="full"
        maxW="md"
        bg="white"
        rounded="xl"
        boxShadow="lg"
        p={6}
        my={12}
        alignItems="center"
        mb={['20', '0']}
        mt={['40', '60']}
      >
        {/* TODO: change px to rem */}
        <Text lineHeight="28px" fontSize="24px" textColor="#1C1E23">
          {UIStrings.SETTING_ACCOUNT}
        </Text>
        <Spinner color="positive" left="50%" top="50%" size="lg" />
        <Button
          variant="link"
          color="indigo.500"
          onClick={() => window.open(UIStrings.STATIC_URLS.TROUBLESHOOT)}
        >
          {UIStrings.BUTTON_TEXT.TROUBLESHOOT}
        </Button>
      </Stack>
    </Background>
  );
}
