import { Box, Stack } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import axios, { AxiosError, AxiosResponse } from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import Background from '../components/Background';
import NavigationPages from '../utils/constants/NavigationPages';
import UIStrings, { NavigationWorkFlowEnum } from '../utils/constants/uIStrings';
import {
  InfoDataType,
  ReVerifyUserDataType,
  PlacementUrlDataType,
  RouteDataType,
  UpgradeScaleDataType,
  UserDataType,
} from '../utils/types/formValues.type';
import {
  ForgotPasswordReqType,
  LoginReqType,
  ResendVerificationLinkReqType,
  SignUpReqType,
} from '../utils/types/apiRequest.type';
import ApiCallMethods from '../utils/constants/api';
import ApiEndPoints from '../utils/constants/endPoints';
import Loader from '../components/Loader';
import fetchUsingAxios from '../utils/service/api';
import MailStatus from '../components/MailStatus';
import { SignUpMaintenanceContextType } from '../utils/types/maintenanceContext.type';
import SignUpMaintenanceContext from '../hooks/useSignUpMaintenanceContext';
import { handleSuccessfulLoginAndRedirect } from '../utils/helper/loginHelper';
import { navigateToUrl } from '../utils/helper/navigator';
import { populatePlacementUrl } from '../utils/helper/helperFunctions';

export default function GoogleCaptchaForm() {
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const { isRunningUnderParsec, parsecDomain } =
    useContext<SignUpMaintenanceContextType>(SignUpMaintenanceContext);
  const [mailSentSuccess, setMailSentSuccess] = useState<boolean>();
  const [isSignUpVerification, setIsSignUpVerification] = useState<boolean>(false);

  useEffect(() => {
    if (location.state) {
      const routeData = location.state as RouteDataType;
      if (!routeData) {
        history.push('/');
      }
    }
  }, [history, location.state]);

  const loginAfterCaptcha = async (
    userDataObject: UserDataType,
    rememberMe: boolean,
    token: string | null,
  ) => {
    try {
      const loginRequest: LoginReqType = {
        username: userDataObject.email.value,
        password: userDataObject.password.value,
        rememberMe: rememberMe,
        verifyToken: token,
        secret: import.meta.env.VITE_GOOGLE_CAPTCHA_V2_SECRET_KEY,
      };

      let endpoint = ApiEndPoints.LOGIN;
      const loginResponse = await fetchUsingAxios(ApiCallMethods.POST, endpoint, loginRequest);
      if (loginResponse) {
        handleSuccessfulLogin(loginResponse, userDataObject, rememberMe);
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.message !== 'Network Error') {
        if (error?.response?.status === 302) {
          history.replace('/');
          return;
        }
        if (error?.response?.status === 409) {
          const reVerifyInfoData: ReVerifyUserDataType = {
            email: userDataObject.email.value,
            error: UIStrings.VALIDATION_TEXT.UNVERIFIED_USER_EXIST,
            areAttemptsExhausted: false,
          };
          history.push({ pathname: NavigationPages.RE_VERIFICATION, state: reVerifyInfoData });
          return;
        }
        const errorData = error?.response?.data as AxiosError;

        const infoData: InfoDataType = {
          userAlreadyExist: false,
          resetPasswordSuccess: false,
          isServerError: true,
          statusCode: error?.response?.status,
          errorMessage: errorData?.message.toString(),
        };
        history.replace({ pathname: '/', state: infoData });
        return;
      }
      history.replace(NavigationPages.ERROR);
    }
  };

  const handleSuccessfulLogin = (
    loginResponse: AxiosResponse,
    userDataObject: UserDataType,
    rememberMe: boolean,
  ) => {
    const workflowToRun = handleSuccessfulLoginAndRedirect(loginResponse, rememberMe.toString());
    if (workflowToRun === NavigationWorkFlowEnum.PRIVATE_GDN) {
      const { privateGdnUrl, isPrivateGdn } = loginResponse.data;
      handlePrivateGDNUrlRedirection(privateGdnUrl, isPrivateGdn);
    }
  };

  const handlePrivateGDNUrlRedirection = (privateGdnUrl: string, isPrivateGdn: boolean) => {
    if (!isPrivateGdn) {
      const upgradeScaleDataType: UpgradeScaleDataType = {
        urlToNavigate: privateGdnUrl,
      };
      history.replace({ pathname: NavigationPages.UPGRADE_SCALE, state: upgradeScaleDataType });
      return;
    }
    navigateToUrl(privateGdnUrl);
  };

  const signInAfterCaptcha = async (
    userDataObject: UserDataType,
    token: string | null,
    placementUrl: string | null = '',
  ) => {
    try {
      const signUpReq: SignUpReqType = {
        email: userDataObject.email.value,
        domain: userDataObject.domain?.value,
        password: userDataObject.password.value,
        attribution: UIStrings.ATTRIBUTION.MACROMETA,
        verifyToken: token,
        secret: import.meta.env.VITE_GOOGLE_CAPTCHA_V2_SECRET_KEY,
      };
      let endpoint = ApiEndPoints.SIGN_UP;

      if (placementUrl) {
        endpoint = placementUrl;
      }

      await fetchUsingAxios(ApiCallMethods.POST, endpoint, signUpReq);
      setMailSentSuccess(true);
    } catch (error) {
      if (axios.isAxiosError(error) && error.message !== 'Network Error') {
        if (error?.response?.status === 302) {
          history.replace({
            pathname: NavigationPages.SIGN_UP,
          });
          return;
        }
        if (error?.response?.status === 409) {
          const infoData: InfoDataType = {
            userAlreadyExist: true,
            resetPasswordSuccess: false,
            errorMessage: '',
          };
          history.replace({ pathname: '/', state: infoData });
          return;
        }
      }
      history.replace(NavigationPages.ERROR);
    }
  };

  const resendVerificationAfterCaptcha = async (
    userDataObject: UserDataType,
    token: string | null,
  ) => {
    try {
      const resendVerificationReq: ResendVerificationLinkReqType = {
        email: userDataObject.email.value,
        attribution: UIStrings.ATTRIBUTION.MACROMETA,
        verifyToken: token,
        secret: import.meta.env.VITE_GOOGLE_CAPTCHA_V2_SECRET_KEY,
      };
      await fetchUsingAxios(
        ApiCallMethods.POST,
        ApiEndPoints.RESEND_VERIFICATION_MAIL,
        resendVerificationReq,
      );
      setIsSignUpVerification(true);
      setMailSentSuccess(true);
    } catch (error) {
      if (axios.isAxiosError(error) && error.message !== 'Network Error') {
        if (error?.response?.status === 302) {
          history.replace({
            pathname: NavigationPages.RE_VERIFICATION,
          });
          return;
        }
        if (error?.response?.status === 403) {
          const errorData = error?.response?.data as AxiosError;
          const reVerifyUserDataType: ReVerifyUserDataType = {
            email: userDataObject.email.value,
            error: errorData?.message.toString(),
            areAttemptsExhausted: true,
          };
          history.replace({
            pathname: NavigationPages.RE_VERIFICATION,
            state: reVerifyUserDataType,
          });
          return;
        }
        history.replace({ pathname: NavigationPages.ERROR });
      }
    }
  };

  const resetPasswordAfterCaptcha = async (userDataObject: UserDataType, token: string | null) => {
    try {
      const forgotPasswordReq: ForgotPasswordReqType = {
        email: userDataObject.email.value,
        verifyToken: token,
        secret: import.meta.env.VITE_GOOGLE_CAPTCHA_V2_SECRET_KEY,
      };
      let endpoint = ApiEndPoints.FORGOT_PASSWORD;

      await fetchUsingAxios(ApiCallMethods.POST, endpoint, forgotPasswordReq);
      const infoData: InfoDataType = {
        userAlreadyExist: false,
        resetPasswordSuccess: true,
        errorMessage: '',
      };
      history.replace({ pathname: NavigationPages.FORGOT_PASSWORD, state: infoData });
      setMailSentSuccess(true);
    } catch (error) {
      if (axios.isAxiosError(error) && error.message !== 'Network Error') {
        // TODO : Simplify this(and related) logic
        if (error?.response?.status === 302) {
          history.replace({
            pathname: NavigationPages.FORGOT_PASSWORD,
          });
          return;
        }
        if (error?.response?.status === 409) {
          history.replace('/');
          return;
        }
        const errorData = error?.response?.data as AxiosError;
        const infoData: InfoDataType = {
          userAlreadyExist: false,
          resetPasswordSuccess: false,
          isServerError: true,
          statusCode: error?.response?.status,
          errorMessage: errorData?.message.toString(),
        };
        history.replace({ pathname: NavigationPages.FORGOT_PASSWORD, state: infoData });
        return;
      }
      history.replace(NavigationPages.ERROR);
    }
  };

  const verifyGoogleTokenAndSignup = async (token: string | null) => {
    const {
      userDataObject,
      rememberMe,
      redirectedFromLogin,
      redirectedFromSignup,
      redirectedFromPasswordReset,
      redirectedFromReverification,
      placementUrl,
    } = location.state as RouteDataType;
    setIsLoading(true);
    try {
      if (redirectedFromLogin) {
        await loginAfterCaptcha(userDataObject, rememberMe, token);
      }
      if (redirectedFromSignup) {
        await signInAfterCaptcha(userDataObject, token, placementUrl);
      }
      if (redirectedFromPasswordReset) {
        await resetPasswordAfterCaptcha(userDataObject, token);
      }
      if (redirectedFromReverification) {
        await resendVerificationAfterCaptcha(userDataObject, token);
      }
      setIsLoading(false);
    } catch (error) {
      if (axios.isAxiosError(error) && error.message !== 'Network Error') {
        if (error?.response?.status === 409 || error?.response?.status === 302) {
          history.replace('/');
          return;
        }
        history.replace(NavigationPages.ERROR);
        return;
      }
      history.replace(NavigationPages.ERROR);
    }
  };

  //TODO use this once parsec is back in picture
  const onReverificationClicked = () => {
    let reverificationUrl = ApiEndPoints.RESEND_VERIFICATION_MAIL;
    const placementUrlData: PlacementUrlDataType = {
      placementUrl: reverificationUrl,
    };
    history.push({ pathname: NavigationPages.RE_VERIFICATION, state: placementUrlData });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      {!mailSentSuccess ? (
        <Background
          showButton={false}
          headerLabel={UIStrings.BACKGROUND_HEADER}
          buttonText={UIStrings.BUTTON_TEXT.SIGN_UP}
          navigationLink={NavigationPages.SIGN_UP}
        >
          <Stack spacing={6} w={['', 'full']} maxW="md" p={6} mb={['20', '0']} mt={['40', '60']}>
            <Box alignSelf="center">
              <ReCAPTCHA
                sitekey={import.meta.env.VITE_GOOGLE_CAPTCHA_V2_SITE_KEY}
                theme="dark"
                onChange={(token: string | null) => verifyGoogleTokenAndSignup(token)}
              />
            </Box>
          </Stack>
        </Background>
      ) : (
        <MailStatus
          isSignUp={isSignUpVerification}
          handleClick={() => history.push(NavigationPages.RE_VERIFICATION)}
        />
      )}
    </div>
  );
}
