/* eslint-disable react/prop-types */
import { Button, Stack, Text } from '@chakra-ui/react';
import NavigationPages from '../utils/constants/NavigationPages';
import UIStrings from '../utils/constants/uIStrings';
import Background from './Background';

export type IMailSuccessProps = {
  isSignUp?: boolean;
  handleClick: () => void;
};

export default function MailStatus({ handleClick, isSignUp = false }: IMailSuccessProps) {
  return (
    <Background
      showButton={!isSignUp}
      buttonText={UIStrings.BUTTON_TEXT.LOG_IN}
      navigationLink={NavigationPages.LOGIN}
    >
      <Stack
        spacing={6}
        w={['', 'full']}
        maxW="md"
        bg="white"
        boxShadow="lg"
        borderRadius="12px"
        p={6}
        mt={['40', '60']}
        alignItems="center"
      >
        <Text
          lineHeight="28px"
          fontSize="21px"
          fontWeight={600}
          textAlign="center"
          textColor="#1C1E23"
        >
          {UIStrings.AWAITING_VERIFICATION}
        </Text>
        <Text
          fontSize="14px"
          fontWeight="400"
          lineHeight="20px"
          textAlign="center"
          textColor="#1C1E23"
        >
          {isSignUp ? UIStrings.MAIL_STATUS_HEADER_FOR_VERIFY : UIStrings.MAIL_STATUS_HEADER}
        </Text>
        <Stack direction={{ base: 'column', sm: 'row' }} spacing={1}>
          <Text
            fontSize="14px"
            fontWeight="600"
            lineHeight="20px"
            textAlign="center"
            textColor="#1C1E23"
          >
            {UIStrings.MAIL_SUBTEXT}
          </Text>
          <Button
            data-cy="resendBtn"
            color="indigo.500"
            variant="link"
            lineHeight="20px"
            onClick={handleClick}
          >
            {UIStrings.BUTTON_TEXT.RESEND}
          </Button>
        </Stack>
      </Stack>
    </Background>
  );
}
