import UIStrings, { CookiesAgeEnum } from '../constants/uIStrings';
import { setCookieValue } from './persist';

export const emailRegex =
  /^((([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+")))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

export const sanitizeUrl = (url: string) => {
  const cleanedUrl = url.replace(/https:\/\//i, '');
  const sanitizedUrl = cleanedUrl.replace(/auth-/i, '');
  return sanitizedUrl;
};

export const getAliasUrl = (alias: string, domain: string, route: string) => {
  return `https://api-${alias}.${domain}/${route}`;
};

export const populatePlacementUrl = (url: string, endpoint: string) => {
  const cleanedUrl = url.replace(/https:\/\//i, '');
  const sanitizedUrl = cleanedUrl.replace(/auth-/i, '');
  return `https://api-${sanitizedUrl}/${endpoint}`;
};
