const buttonStyles = {
  Button: {
    variants: {
      link: {
        fontWeight: '400',
        textColor: 'indigo.500',
        color: 'positive',
        bg: 'transparent',
        fontSize: '14',
        h: 'auto',
        _hover: { color: 'positive_hover', textDecoration: 'none' },
        _focus: { color: 'positive_hover', textDecoration: 'none' },
        _active: { color: 'positive_hover', textDecoration: 'none' },
      },
      primary: {
        bg: 'indigo.500',
        color: 'white',
        _hover: {
          bg: 'indigo.600',
        },
      },
      outline: {
        colorScheme: 'teal',
        textColor: '#383B46',
        height: '32px',
        padding: '10px,16px,10px,16px',
        borderRadius: '16px',
        borderColor: '#383B46',
        _hover: {
          bg: 'transparent',
        },
      },
    },
    baseStyle: {
      lineHeight: '8',
    },
    defaultProps: {
      variant: 'solid',
    },
    sizes: {
      md: {
        fontSize: '13',
        h: '8',
      },
    },
  },
};

export default buttonStyles;
