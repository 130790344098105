import { Heading, Stack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { parse } from 'query-string';
import useApi from '../hooks/useApi';
import ApiCallMethods from '../utils/constants/api';
import { passwordRegex } from '../utils/helper/helperFunctions';
import { ChangePasswordReqType } from '../utils/types/apiRequest.type';
import { ResetPasswordDataType } from '../utils/types/formValues.type';
import Background from '../components/Background';
import ForgotResetForm from '../components/ForgotResetForm';
import Success from '../components/Success';
import AlertMessage from '../components/AlertMessage';
import NavigationPages from '../utils/constants/NavigationPages';
import ApiEndPoints from '../utils/constants/endPoints';
import UIStrings from '../utils/constants/uIStrings';

export default function ResetPasswordForm() {
  // #region State Initialisations
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const { response, getApiResult } = useApi();
  const [showSuccess, setShowSuccess] = useState<boolean>();
  const [querryToken, setQuerryToken] = useState<string | (string | null)[]>('');
  const [userData, setUserData] = useState<ResetPasswordDataType>({
    confirmPassword: {
      value: '',
      error: false,
      alreadyValidated: false,
      helperText: '',
    },
    password: {
      value: '',
      error: false,
      alreadyValidated: false,
      helperText: '',
    },
  });
  const [displayAlert, setDisplayAlert] = useState<'none' | 'flex'>('none');
  // #endregion State Initialisations

  // #region  Effects
  useEffect(() => {
    try {
      const { token } = parse(location.search);
      if (token) {
        setQuerryToken(token);
      } else {
        history.replace('/');
      }
    } catch {
      history.replace({ pathname: NavigationPages.ERROR });
    }
  }, [location.search, history]);

  useEffect(() => {
    if (response?.isSuccess) {
      setDisplayAlert('none');
      setShowSuccess(true);
    } else {
      setDisplayAlert('flex');
    }
  }, [response]);
  // #endregion

  const validatePassword = (password: string) => {
    if (password.length === 0) {
      setUserData((userDataObject) => ({
        ...userDataObject,
        password: {
          error: true,
          helperText: UIStrings.VALIDATION_TEXT.PASSWORD_CANNOT_BE_EMPTY,
          value: password,
          alreadyValidated: false,
        },
      }));
      return false;
    } else if (!passwordRegex.test(password)) {
      setUserData((userDataObject) => ({
        ...userDataObject,
        password: {
          error: true,
          helperText: UIStrings.VALIDATION_TEXT.PASSWORD_FORM,
          value: password,
          alreadyValidated: false,
        },
      }));
      return false;
    } else {
      setUserData((userDataObject) => ({
        ...userDataObject,
        password: {
          error: false,
          helperText: '',
          value: password,
          alreadyValidated: true,
        },
      }));
      return true;
    }
  };

  const validateConfirmPassword = (confirmPassword: string) => {
    const { password } = userData;
    if (confirmPassword.length === 0) {
      setUserData((userDataObject) => ({
        ...userDataObject,
        confirmPassword: {
          error: true,
          helperText: UIStrings.VALIDATION_TEXT.CONFIRM_PASSWORD_CANNOT_BE_EMPTY,
          value: confirmPassword,
          alreadyValidated: false,
        },
      }));
      return false;
    } else if (password.value !== confirmPassword) {
      setUserData((userDataObject) => ({
        ...userDataObject,
        confirmPassword: {
          error: true,
          helperText: UIStrings.VALIDATION_TEXT.PASS_CONFIRM_NOT_MATCH,
          value: confirmPassword,
          alreadyValidated: false,
        },
      }));
      return false;
    } else {
      setUserData((userDataObject) => ({
        ...userDataObject,
        confirmPassword: {
          error: false,
          helperText: '',
          value: confirmPassword,
          alreadyValidated: true,
        },
      }));
      return true;
    }
  };
  // #region  Validations
  const inputValidation = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    if (event.target.id === 'new-password') {
      validatePassword(event.target.value);
    }
    if (event.target.id === 'confirm-password') {
      validateConfirmPassword(event.target.value);
    }
  };
  // #endregion

  // #region Event handlers
  const handleBlurForFormLabel1 = () => {
    if (userData.password.value.length === 0) {
      setUserData((userDataObject) => ({
        ...userDataObject,
        password: {
          error: false,
          helperText: '',
          value: '',
          alreadyValidated: false,
        },
      }));
    }
  };

  const handleBlurForFormLabel2 = () => {
    if (userData.confirmPassword.value.length === 0) {
      setUserData((userDataObject) => ({
        ...userDataObject,
        confirmPassword: {
          error: false,
          helperText: '',
          value: '',
          alreadyValidated: true,
        },
      }));
    }
  };

  const handleOnClose = () => {
    setDisplayAlert('none');
  };

  const onFormLabel1Focus = () => {
    setDisplayAlert('none');
  };

  // #endregion

  // #region Api call
  const handleChangePassword = () => {
    const { password, confirmPassword } = userData;
    if (validatePassword(password.value) && validateConfirmPassword(confirmPassword.value)) {
      setLoading(true);
      const changePasswordReq: ChangePasswordReqType = { password: userData.password.value };
      getApiResult(
        ApiCallMethods.POST,
        `${ApiEndPoints.CHANGE_PASSWORD}${querryToken}`,
        changePasswordReq,
      );
      setLoading(false);
    }
  };
  // #endregion

  if (showSuccess) {
    return <Success />;
  }

  return (
    <Background buttonText={UIStrings.BUTTON_TEXT.LOG_IN} navigationLink={NavigationPages.LOGIN}>
      <Stack
        spacing={6}
        w={['', 'full']}
        maxW="md"
        bg="white"
        boxShadow="lg"
        borderRadius="12px"
        p={6}
        mb={['20', '0']}
        mt={['40', '60']}
      >
        <Heading fontSize="21px" fontStyle="normal" fontWeight="700" lineHeight="28px">
          Set a new password
        </Heading>
        {response?.isSuccess === false && (
          <AlertMessage
            alertType="error"
            alertMessage={response?.data?.message?.toString()}
            displayAlert={displayAlert}
            onClose={handleOnClose}
            isOpen={response?.isSuccess === false}
          />
        )}
        <ForgotResetForm
          formLabel1={UIStrings.PASSWORD}
          formLabel1PlaceHolder="••••••••••••"
          formLabel1Id="new-password"
          formLabel1error={userData.password.error}
          formLabel1helperText={userData.password.helperText}
          formLabel2={UIStrings.CONFIRM_PASSWORD}
          formLabel2error={userData.confirmPassword.error}
          formLabel2helperText={userData.confirmPassword.helperText}
          showformLabel2
          inpuType="password"
          isLoading={loading}
          onFormLabel1Focus={onFormLabel1Focus}
          validateEmailPassword={inputValidation}
          handleClick={handleChangePassword}
          buttonLabel={UIStrings.BUTTON_TEXT.SUBMIT}
          onFormLabel1Blur={handleBlurForFormLabel1}
          onFormLabel2Blur={handleBlurForFormLabel2}
          buttonDisabled={false}
        />
      </Stack>
    </Background>
  );
}
