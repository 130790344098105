import { FormControl, FormLabel, Input, Stack, Button, FormErrorMessage } from '@chakra-ui/react';
import React from 'react';
import UIStrings from '../utils/constants/uIStrings';

export type IReverificationFormProps = {
  buttonLabel: string;
  isLoading: boolean;
  prefillValue: string;
  validateEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
  formLabelerror: boolean;
  formLabelhelperText: string;
  handleClick: () => void;
  onBlur: () => void;
  buttonDisabled: boolean;
};

export default function ReverificationForm({
  formLabelerror,
  formLabelhelperText,
  buttonLabel,
  prefillValue,
  isLoading,
  validateEmail,
  handleClick,
  onBlur,
  buttonDisabled,
}: IReverificationFormProps) {
  return (
    <>
      <Stack spacing="0.75rem" align="center" justify="center" w="full">
        <FormControl id="email" pb={1} isInvalid={formLabelerror} isRequired>
          <FormLabel fontWeight={600} fontSize="14px" lineHeight="20px" pb={0.25}>
            Email Address
          </FormLabel>
          <Input
            type="email"
            maxW="md"
            bg="white"
            value={prefillValue}
            fontWeight={400}
            fontSize="14px"
            lineHeight="20px"
            onBlur={onBlur}
            onChange={validateEmail}
            focusBorderColor={formLabelerror ? 'red.500' : 'blue.500'}
          />
          {formLabelerror && <FormErrorMessage>{formLabelhelperText}</FormErrorMessage>}
        </FormControl>
      </Stack>
      <Stack spacing={5} pt={3}>
        <Button
          bg="indigo.500"
          color="white"
          _hover={{
            bg: 'indigo.500',
          }}
          borderRadius="4px"
          height="40px"
          onClick={handleClick}
          disabled={buttonDisabled || isLoading}
          isLoading={isLoading}
        >
          {buttonLabel}
        </Button>
      </Stack>
    </>
  );
}
