import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Input,
  InputGroup,
  InputRightAddon,
  SimpleGrid,
  Stack,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import NavigationPages from '../utils/constants/NavigationPages';
import UIStrings from '../utils/constants/uIStrings';
import { DomainDataType } from '../utils/types/formValues.type';

export type IDomainEntry = {
  isLoading?: boolean;
  subDomain: string;
  validateDomainText: (event: React.ChangeEvent<HTMLInputElement>) => void;
  domainData: DomainDataType;
  handleNext: () => void;
  onDomainEntryFocus: () => void;
};

export default function DomainEntry({
  isLoading,
  subDomain,
  validateDomainText,
  domainData,
  handleNext,
  onDomainEntryFocus,
}: IDomainEntry) {
  const history = useHistory();
  return (
    <SimpleGrid rowGap={4} w="full" maxW="lg">
      <GridItem>
        <FormControl id="text" pb={1} isInvalid={domainData.domain.error} isRequired>
          <Stack direction={{ base: 'column', sm: 'row' }} align="start" justify="space-between">
            <FormLabel textColor={domainData.domain.error ? 'red.500' : 'gray.700'}>
              {UIStrings.DOMAIN}
            </FormLabel>
            <Button
              lineHeight="1.25rem"
              data-cy="forgotPassBtn"
              bottom={[3, 0, 0]}
              variant="link"
              onClick={() => history.push(NavigationPages.DOMAIN_RECOVERY)}
            >
              {UIStrings.FORGOT_ALIAS}
            </Button>
          </Stack>
          <InputGroup>
            <Input
              type="text"
              fontSize="sm"
              borderRadius="1px"
              maxW="md"
              bg="white"
              data-cy="domain"
              onChange={validateDomainText}
              onFocus={onDomainEntryFocus}
              focusBorderColor={domainData.domain.error ? 'red.500' : 'blue.500'}
            />
            {subDomain && <InputRightAddon borderRadius="1px" children={subDomain} />}
          </InputGroup>
          {domainData.domain.error && (
            <FormErrorMessage fontSize="sm" fontWeight="400" lineHeight="1.25rem">
              {domainData.domain.helperText}
            </FormErrorMessage>
          )}
        </FormControl>
      </GridItem>

      <Button
        id="nextBtn"
        isLoading={isLoading}
        spinnerPlacement="start"
        variant="primary"
        _hover={{
          bg: 'indigo.600',
        }}
        onClick={handleNext}
        borderRadius="4px"
        height="2.5rem"
        onFocus={onDomainEntryFocus}
        fontSize="md"
      >
        {UIStrings.BUTTON_TEXT.NEXT}
      </Button>
    </SimpleGrid>
  );
}
