/* eslint-disable react/require-default-props */
import {
  Flex,
  BoxProps,
  Box,
  Button,
  ButtonGroup,
  Center,
  Spacer,
  Image,
  Stack,
  HStack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import LogomarkDark from '../assets/images/logomark-dark.svg';
import LogomarkLight from '../assets/images/logomark-white.svg';
import Eclipse from '../assets/images/ellipse.svg';
import UIStrings from '../utils/constants/uIStrings';

interface BackgroundProps extends BoxProps {
  headerLabel?: string;
  buttonText?: string;
  navigationLink?: string;
  isSignUp?: boolean;
  showButton?: boolean;
}

export default function Background({
  children,
  headerLabel,
  buttonText,
  navigationLink = '',
  isSignUp = false,
  showButton = true,
}: BackgroundProps) {
  const history = useHistory();

  const logoImg = useBreakpointValue({ base: LogomarkDark, md: LogomarkLight });
  return (
    <Flex
      align="fill"
      justify="space-evenly"
      bgGradient="linear-gradient(230.74deg, rgba(27, 222, 175, 0.6) 9.33%, rgba(44, 116, 216, 0) 67.53%), linear-gradient(125.5deg, #6767E6 26.9%, #E67CA8 180.47%)"
    >
      <Flex minW="100%" alignItems="center" py="5" pos="absolute" top={0}>
        <Box p="2" maxW="xl" marginStart={[2, 10, 20]} position="sticky" zIndex={2}>
          <Image
            src={isSignUp ? logoImg : LogomarkDark}
            color="gray.500"
            alt="Macrometa"
            height="30px"
            width="144px"
            marginStart="4.5%"
            onClick={() => window.open(UIStrings.STATIC_URLS.MACROMETA)}
          />
        </Box>

        <Spacer />
        {showButton && (
          <ButtonGroup data-cy="linkBtnGrp" gap={[-2, 2, 3]} mx="1rem" zIndex={2} mr={[5, 24]}>
            <Center
              data-cy="linkTxt"
              fontSize={14}
              fontWeight={400}
              visibility={['hidden', 'visible']}
            >
              {headerLabel}
            </Center>
            <Button
              data-cy="linkBtn"
              variant="outline"
              onClick={() => {
                history.push(navigationLink);
              }}
            >
              {buttonText}
            </Button>
          </ButtonGroup>
        )}
      </Flex>
      <Flex>
        <Stack
          minW="100vw"
          minH="100vh"
          bg="whiteTransp"
          display="flex"
          maxW="[auto , md]"
          maxH="[auto , md]"
          alignItems="center"
        >
          {children}
        </Stack>
      </Flex>
      <Flex
        justify="space-evenly"
        alignItems="flex-end"
        pos="absolute"
        bottom={[3, 4]}
        left={isSignUp ? ['-5%', '-35%', '-50%'] : '[5%,15%,30%]'}
        right={isSignUp ? 0 : '[5%,15%,30%]'}
      >
        <Center fontSize="sd" fontWeight="light" textColor={isSignUp ? 'white' : 'gray.700'}>
          <HStack>
            <Text fontWeight={400} fontSize="14px">
              {UIStrings.MACROMETA}
            </Text>
            <Image src={Eclipse} verticalAlign="center" />
            <Button
              variant="link"
              textColor={isSignUp ? 'white' : 'gray.700'}
              fontWeight={400}
              fontSize="14px"
              data-cy="termsBtn"
              onClick={() => window.open(UIStrings.STATIC_URLS.TERMS)}
            >
              {UIStrings.TERMS}
            </Button>
            <Image src={Eclipse} verticalAlign="center" />
            <Button
              variant="link"
              textColor={isSignUp ? 'white' : 'gray.700'}
              fontWeight={400}
              fontSize="14px"
              data-cy="privacyBtn"
              onClick={() => window.open(UIStrings.STATIC_URLS.PRIVACY)}
            >
              {UIStrings.PRIVACY_POLICY}
            </Button>
          </HStack>
        </Center>
      </Flex>
    </Flex>
  );
}
