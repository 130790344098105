/* eslint-disable react/require-default-props */
import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  GridItem,
} from '@chakra-ui/react';
import { ViewOffIcon } from '@chakra-ui/icons';
import React, { useState } from 'react';
import Eye from '../assets/images/eye';
import UIStrings from '../utils/constants/uIStrings';
import { UserDataType } from '../utils/types/formValues.type';

export type IForgotResetFormProps = {
  formLabel1: string;
  formLabel1PlaceHolder: string;
  formLabel1Id: string;
  formLabel2: string;
  formLabel2error: boolean;
  disableShowPassword?: boolean;
  formLabel2helperText: string;
  isLoading: boolean;
  showformLabel2: boolean;
  buttonLabel: string;
  validateEmailPassword: (event: React.ChangeEvent<HTMLInputElement>) => void;
  validateFormEntry?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  formLabel1error: boolean;
  formLabel1helperText: string;
  handleClick: () => void;
  onFormLabel1Blur: () => void;
  onFormLabel2Blur: () => void;
  onFormLabel1Focus: () => void;
  userData?: UserDataType;
  buttonDisabled: boolean;
  inpuType: 'email' | 'password';
};

export default function ForgotResetForm({
  formLabel1,
  formLabel1PlaceHolder,
  formLabel1Id,
  formLabel1error,
  formLabel1helperText,
  formLabel2,
  disableShowPassword = false,
  formLabel2error,
  formLabel2helperText,
  isLoading,
  showformLabel2,
  buttonLabel,
  validateEmailPassword,
  validateFormEntry,
  handleClick,
  onFormLabel1Blur,
  onFormLabel1Focus,
  onFormLabel2Blur,
  buttonDisabled,
  userData,
  inpuType,
}: IForgotResetFormProps) {
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword((showPasswordValue) => !showPasswordValue);
  };
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((showPasswordValue) => !showPasswordValue);
  };
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <>
      <SimpleGrid rowGap={4} w="full" maxW="lg">
        <GridItem>
          <FormControl
            id={formLabel1Id}
            pb={1}
            isInvalid={formLabel1error}
            w={{ base: '90', md: '104' }}
            isRequired
          >
            <FormLabel
              fontSize="14px"
              fontWeight="600"
              lineHeight="20px"
              textColor={formLabel1error ? 'red.500' : '#383B46'}
              pb={0.25}
            >
              {formLabel1}
            </FormLabel>
            <InputGroup size="md">
              <Input
                type={
                  (inpuType === 'password' && showPassword) || inpuType === 'email'
                    ? 'text'
                    : 'password'
                }
                borderRadius="4px"
                fontSize="14px"
                bg="white"
                onBlur={onFormLabel1Blur}
                data-cy="password"
                onFocus={onFormLabel1Focus}
                placeholder={formLabel1PlaceHolder}
                onChange={validateEmailPassword}
                onKeyDown={(event) => {
                  inpuType === 'email' && handleKeyDown(event);
                }}
                focusBorderColor={formLabel1error ? 'red.500' : 'blue.500'}
              />
              {!disableShowPassword ? (
                <InputRightElement>
                  {!showPassword ? (
                    <Eye onClick={handleShowPassword} w="16px" h="16px" />
                  ) : (
                    <ViewOffIcon onClick={handleShowPassword} color="gray.300" w="16px" h="16px" />
                  )}
                </InputRightElement>
              ) : (
                <></>
              )}
            </InputGroup>
            {formLabel1error && <FormErrorMessage>{formLabel1helperText}</FormErrorMessage>}
          </FormControl>
        </GridItem>
        {showformLabel2 && (
          <GridItem>
            <FormControl
              id="confirm-password"
              pb={0.75}
              isInvalid={formLabel2error}
              w={{ base: '90', md: '104' }}
              isRequired
            >
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                align="start"
                justify="space-between"
              >
                <FormLabel
                  fontWeight="600"
                  lineHeight="20px"
                  pb={0.25}
                  textColor={formLabel2error ? 'red.500' : '#383B46'}
                >
                  {formLabel2}
                </FormLabel>
              </Stack>
              <InputGroup size="md">
                <Input
                  bg="white"
                  borderRadius="4px"
                  fontSize="14px"
                  type={showConfirmPassword ? 'text' : 'password'}
                  onBlur={onFormLabel2Blur}
                  placeholder="••••••••••••"
                  data-cy="confirm-password"
                  onChange={validateEmailPassword}
                  focusBorderColor={formLabel2error ? 'red.500' : 'blue.500'}
                  onKeyDown={handleKeyDown}
                />
                <InputRightElement>
                  {!showConfirmPassword ? (
                    <Eye onClick={handleShowConfirmPassword} w="16px" h="16px" />
                  ) : (
                    <ViewOffIcon
                      onClick={handleShowConfirmPassword}
                      color="gray.300"
                      w="16px"
                      h="16px"
                    />
                  )}
                </InputRightElement>
              </InputGroup>
              {formLabel2error && <FormErrorMessage>{formLabel2helperText}</FormErrorMessage>}
            </FormControl>
          </GridItem>
        )}
      </SimpleGrid>
      <Stack spacing={5} pt={5} w="full">
        <Button
          variant="primary"
          _hover={{
            bg: 'indigo.500',
          }}
          id="actionResetBtn"
          data-cy="submit"
          isLoading={isLoading}
          borderRadius="4px"
          height="40px"
          fontSize="14px"
          onClick={handleClick}
          disabled={buttonDisabled || isLoading}
        >
          {buttonLabel}
        </Button>
      </Stack>
    </>
  );
}
