import { Stack, Text } from '@chakra-ui/react';
import UIStrings from '../utils/constants/uIStrings';
import Background from './Background';

const { LINK_EXPIRED, LINK_TEXT, SUPPORT } = UIStrings.INVITE_USER;

export default function LinkExpired() {
  return (
    <Background showButton={false}>
      <Stack
        spacing={4}
        w="full"
        maxW="md"
        bg="white"
        rounded="xl"
        boxShadow="lg"
        align="center"
        p={10}
        my={12}
        mb={['20', '0']}
        mt={['40', '60']}
      >
        <Text lineHeight="28px" fontSize="24px" textColor="#1C1E23" fontWeight="700">
          {LINK_EXPIRED}
        </Text>
        <Text fontSize="md" fontWeight="400" textColor="#1C1E23">
          {LINK_TEXT}
        </Text>
        <Stack direction={{ base: 'column', sm: 'row' }}>
          <Text fontSize="md" textColor="#1C1E23" fontWeight="600">
            {SUPPORT}
          </Text>
        </Stack>
      </Stack>
    </Background>
  );
}
