import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  SimpleGrid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  Stack,
  InputGroup,
  InputRightElement,
  Wrap,
  Button,
  Text,
  FormErrorMessage,
} from '@chakra-ui/react';
import React, { useCallback, useMemo, useState } from 'react';
import UIStrings from '../utils/constants/uIStrings';
import { passwordRegex } from '../utils/helper/helperFunctions';
import { UserInfo } from '../utils/types/CreateUser.types';

interface Props {
  readonly getUserInfo: ({ password, displayName }: UserInfo) => void;
  readonly email: string;
}

export default function CreateUserForm({ getUserInfo, email }: Props) {
  const {
    INVITE_USER: { DISPLAY_NAME, TERMS, PRIVACY_POLICY, SIGNING_UP },
    PASSWORD,
    EMAIL_ADDRESS,
  } = UIStrings;

  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [displayNameError, setDisplayNameError] = useState('');
  const [userInfo, setUserInfo] = useState({
    password: '',
    displayName: '',
  });

  const { password, displayName } = userInfo;

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setUserInfo((userData) => ({ ...userData, [name]: value }));

    switch (name) {
      case 'password':
        setPasswordError(passwordRegex.test(value) ? '' : UIStrings.VALIDATION_TEXT.PASSWORD_FORM);
        break;
      case 'displayName':
        setDisplayNameError(value.length > 80 ? UIStrings.VALIDATION_TEXT.DISPLAY_NAME_MAX_LENGTH_EXCEED : '');
        break;
      default:
        break;
    }
  }, []);

  const isPasswordInValid = useMemo(() => passwordError.length > 0, [passwordError.length]);
  const isDisplayNameInValid = useMemo(
    () => displayNameError.length > 0,
    [displayNameError.length],
  );

  return (
    <>
      <SimpleGrid rowGap={4} w="full" maxW="lg">
        <GridItem>
          <FormControl id="email" pb={1} w={{ base: '90', md: '104' }} isRequired>
            <FormLabel fontSize="sd" fontWeight="semibold" pb={0.25}>
              {EMAIL_ADDRESS}
            </FormLabel>
            <Input
              type="email"
              maxW="md"
              bg="white"
              placeholder="Email Address"
              autoFocus
              name="email"
              value={email}
              disabled
            />
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl
            id="displayName"
            pb={1}
            w={{ base: '90', md: '104' }}
            isRequired
            isInvalid={isDisplayNameInValid}
          >
            <FormLabel fontSize="sd" fontWeight="semibold" pb={0.25}>
              {DISPLAY_NAME}
            </FormLabel>
            <Input
              type="text"
              maxW="md"
              bg="white"
              placeholder=""
              autoFocus
              name="displayName"
              focusBorderColor={isDisplayNameInValid ? 'red.500' : 'blue.500'}
              onChange={handleChange}
            />
            {isDisplayNameInValid && <FormErrorMessage>{displayNameError}</FormErrorMessage>}
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl
            id="password"
            pb={0.75}
            isInvalid={isPasswordInValid}
            w={{ base: '90', md: '104' }}
            isRequired
          >
            <Stack direction={{ base: 'column', sm: 'row' }} align="start" justify="space-between">
              <FormLabel fontSize="sd" fontWeight="semibold" pb={0.25}>
                {PASSWORD}
              </FormLabel>
            </Stack>
            <InputGroup size="md">
              <Input
                bg="white"
                type={showPassword ? 'text' : 'password'}
                placeholder="••••••••••••"
                name="password"
                onChange={handleChange}
                focusBorderColor={isPasswordInValid ? 'red.500' : 'blue.500'}
              />
              <InputRightElement>
                {showPassword ? (
                  <ViewIcon
                    onClick={() => setShowPassword(false)}
                    color="gray.500"
                    w="16px"
                    h="16px"
                  />
                ) : (
                  <ViewOffIcon
                    onClick={() => setShowPassword(true)}
                    color="gray.500"
                    w="16px"
                    h="16px"
                  />
                )}
              </InputRightElement>
            </InputGroup>
            {isPasswordInValid && <FormErrorMessage>{passwordError}</FormErrorMessage>}
          </FormControl>
        </GridItem>
      </SimpleGrid>
      <Stack spacing={5} pt={5}>
        <Wrap direction="row" spacing="1">
          <Text fontSize="md" fontWeight="400" color="gray.600">
            {SIGNING_UP}
          </Text>
          <Button
            variant="link"
            fontWeight="light"
            textColor="indigo.500"
            onClick={() => window.open('https://www.macrometa.com/terms-of-service')}
          >
            {TERMS}
          </Button>
          <Text variant="subText">and</Text>
          <Text variant="subText">have</Text>
          <Text variant="subText">read</Text>
          <Text variant="subText">our</Text>
          <Button
            variant="link"
            ml="-1.5"
            fontWeight="light"
            textColor="indigo.500"
            onClick={() => window.open('https://www.macrometa.com/privacy-policy')}
          >
            {PRIVACY_POLICY}
          </Button>
        </Wrap>
        <Button
          variant="primary"
          borderRadius="4px"
          height="2.5rem"
          onClick={() => getUserInfo(userInfo)}
          fontSize="sd"
          _hover={{
            bg: 'primary',
          }}
          disabled={
            isPasswordInValid ||
            isDisplayNameInValid ||
            password.length === 0 ||
            displayName.length === 0
          }
        >
          Sign up
        </Button>
      </Stack>
    </>
  );
}
