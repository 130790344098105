import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Heading,
  Input,
  SimpleGrid,
  Stack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import AlertMessage from '../components/AlertMessage';
import Background from '../components/Background';
import DomainMailStatus from '../components/DomainMailStatus';
import useApi from '../hooks/useApi';
import ApiCallMethods from '../utils/constants/api';
import ApiEndPoints from '../utils/constants/endPoints';
import NavigationPages from '../utils/constants/NavigationPages';
import UIStrings from '../utils/constants/uIStrings';
import { emailRegex } from '../utils/helper/helperFunctions';
import { DomainRecoveryDataType } from '../utils/types/formValues.type';

export default function DomainRecovery() {
  //#region states and context
  const { response, getApiResult } = useApi();
  const [displayAlert, setDisplayAlert] = useState<'none' | 'flex'>('none');
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mailSentSuccess, setMailSentSuccess] = useState<boolean>();
  const [domainRecoveryData, setDomainRecoveryData] = useState<DomainRecoveryDataType>({
    email: {
      value: '',
      error: false,
      helperText: '',
    },
  });
  const {
    EMAIL_ADDRESS,
    VALIDATION_TEXT: { VALID_EMAIL },
    BUTTON_TEXT: { LOG_IN, SUBMIT },
    DOMAIN_RECOVERY,
  } = UIStrings;
  //#endregion

  //#region effects
  useEffect(() => {
    if (response) {
      setMailSentSuccess(true);
      setIsLoading(false);
    }
  }, [response]);
  //#endregion

  //#region Event Handlers
  const onDomainEntryFocus = () => {
    setDisplayAlert('none');
  };

  const handleOnAlertClose = () => {
    setDisplayAlert('none');
  };

  const onSubmitClicked = async () => {
    try {
      const email = domainRecoveryData.email.value;
      if (email.trim() && !emailRegex.test(email)) {
        setDomainRecoveryData((userDataObject) => ({
          ...userDataObject,
          email: {
            error: true,
            helperText: VALID_EMAIL,
            value: email,
            alreadyValidated: false,
          },
        }));
      } else {
        setIsLoading(true);
        await getApiResult(ApiCallMethods.GET, `${ApiEndPoints.FORGOT_ALIAS}${email}`);
      }
    } catch (error) {
      setMailSentSuccess(true);
      setIsLoading(false);
    }
  };
  //#endregion

  //#region validations and domain-data state mgmt
  const validateEmailText = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const { value } = event.target;
    processValidationsForEmail(value);
  };

  const processValidationsForEmail = (value: string) => {
    if (value.trim() && !emailRegex.test(value)) {
      populateDomainData('', VALID_EMAIL, true);
    } else {
      populateDomainData(value, '', false);
    }
  };

  const populateDomainData = (value: string, validationText: string, isError: boolean) => {
    setDomainRecoveryData((userDataObject) => ({
      ...userDataObject,
      email: {
        error: isError,
        helperText: validationText,
        value,
      },
    }));
  };
  //#endregion

  if (mailSentSuccess) {
    return (
      <DomainMailStatus
        handleClick={() => {
          setDomainRecoveryData((userDataObject) => ({
            ...userDataObject,
            email: {
              error: false,
              helperText: '',
              value: '',
            },
          }));
          setMailSentSuccess(false);
        }}
      />
    );
  }
  return (
    <Box>
      <Background buttonText={LOG_IN} navigationLink={NavigationPages.ROOT}>
        <Stack
          spacing={6}
          w={['', 'full']}
          maxW="md"
          bg="white"
          boxShadow="lg"
          borderRadius="12px"
          p={6}
          mb={['20', '0']}
          mt={['40', '60']}
        >
          <Box rounded="lg" maxW="md" bg="white">
            <Stack align="start" pb="13px">
              <Heading fontSize="2xl" fontStyle="normal" fontWeight="700" lineHeight="28px">
                {DOMAIN_RECOVERY}
              </Heading>
            </Stack>
            <AlertMessage
              alertType="error"
              alertMessage={alertMessage}
              displayAlert={displayAlert}
              onClose={handleOnAlertClose}
              isOpen
            />
            <SimpleGrid rowGap={4} w="full" maxW="lg">
              <GridItem>
                <FormControl id="text" pb={1} isInvalid={domainRecoveryData.email.error} isRequired>
                  <FormLabel textColor={domainRecoveryData.email.error ? 'red.500' : 'gray.700'}>
                    {EMAIL_ADDRESS}
                  </FormLabel>
                  <Input
                    type="text"
                    borderRadius="4px"
                    fontSize="sm"
                    maxW="md"
                    bg="white"
                    data-cy="domainEmail"
                    onChange={validateEmailText}
                    onFocus={onDomainEntryFocus}
                    focusBorderColor={domainRecoveryData.email.error ? 'red.500' : 'blue.500'}
                  />
                  {domainRecoveryData.email.error && (
                    <FormErrorMessage fontSize="sm" fontWeight="400" lineHeight="20px">
                      {domainRecoveryData.email.helperText}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
              <Button
                id="nextBtn"
                isLoading={isLoading}
                data-cy="submit"
                spinnerPlacement="start"
                variant="primary"
                _hover={{
                  bg: 'indigo.600',
                }}
                onClick={onSubmitClicked}
                borderRadius="4px"
                height="2.5rem"
                onFocus={onDomainEntryFocus}
                fontSize="md"
              >
                {SUBMIT}
              </Button>
            </SimpleGrid>
          </Box>
        </Stack>
      </Background>
    </Box>
  );
}
