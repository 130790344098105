enum NavigationPages {
  ROOT = '/',
  //TODO remove LOGIN dependency from old code and make use of ROOT
  LOGIN = '/',
  RE_VERIFICATION = '/re-verification',
  SIGN_UP = '/sign-up',
  FORGOT_PASSWORD = '/forget-password',
  RESET_PASSWORD = '/reset-password',
  VERIFY_EMAIL = '/update-email/verify',
  VERIFY = '/sign-up/verify',
  MAINTENANCE = '/maintenance',
  ERROR = '/error',
  RECAPTCHA = '/recaptcha',
  INVITE_USER = '/invitation/:invitationId',
  UPGRADE_SCALE = '/upgrade',
  DOMAIN_RECOVERY = '/domain-recovery',
}

export default NavigationPages;
