const textStyles = {
  Text: {
    baseStyle: {},
    variants: {
      subText: {
        fontSize: 'md',
        fontWeight: '400',
        color: 'gray.600',
      },
    },
  },
};

export default textStyles;
