import { createIcon } from '@chakra-ui/icon';

const Eye = createIcon({
  displayName: 'ExternalLinkIcon',
  viewBox: '0 0 16 16',
  path: [
    <path
      d="M0.666687 7.99996C0.666687 7.99996 3.33335 2.66663 8.00002 2.66663C12.6667 2.66663 15.3334 7.99996 15.3334 7.99996C15.3334 7.99996 12.6667 13.3333 8.00002 13.3333C3.33335 13.3333 0.666687 7.99996 0.666687 7.99996Z"
      stroke="#A9ADB9"
      fill="white"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
      stroke="#A9ADB9"
      strokeWidth="1.4"
      fill="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
export default Eye;
