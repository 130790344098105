const formLabelStyles = {
  FormLabel: {
    baseStyle: {
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '20px',
      pb: '0.25',
    },
  },
};

export default formLabelStyles;
