import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { parse } from 'query-string';
import { Box, Spinner, Stack, Text } from '@chakra-ui/react';
import useApi from '../hooks/useApi';
import Background from '../components/Background';
import ApiCallMethods from '../utils/constants/api';
import ApiEndPoints from '../utils/constants/endPoints';
import NavigationPages from '../utils/constants/NavigationPages';
import UIStrings from '../utils/constants/uIStrings';

export default function VerifyUpdateEmail() {
  const location = useLocation();
  const history = useHistory();
  const [verificationMessage, setVerificationMessage] = useState(
    'One moment, updating your email.',
  );
  const { response, getApiResult } = useApi();
  const [loading, setLoading] = useState<boolean>(false);
  const verifyEmail = async (token: string | (string | null)[]) => {
    setLoading(true);
    await getApiResult(ApiCallMethods.GET, `${ApiEndPoints.VERIFY_EMAIL}${token}`);
    setLoading(false);
  };

  useEffect(() => {
    const { token } = parse(location.search);
    if (token) {
      verifyEmail(token);
    } else {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (response) {
      if (response?.isSuccess) {
        setLoading(false);
        setVerificationMessage('Email Updated Successfully.');
      } else {
        history.push({ pathname: NavigationPages.ERROR });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  return (
    <div>
      <Background  buttonText={UIStrings.BUTTON_TEXT.LOG_IN}
        navigationLink={NavigationPages.LOGIN}>
        <Stack
          spacing={6}
          w={['', 'full']}
          maxW="md"
          bg="white"
          boxShadow="lg"
          borderRadius="12px"
          p={6}
          mb={['20', '0']}
          mt={['40', '60']}
          alignItems="center"
        >
          <Box rounded="lg" maxW="md" bg="white" alignContent="center">
            <Text lineHeight="28px" fontSize="21px" textColor="#1C1E23">
              {verificationMessage}
            </Text>
          </Box>
          {loading && <Spinner color="positive" left="50%" top="50%" size="lg" />}
        </Stack>
      </Background>
    </div>
  );
}
