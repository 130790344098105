import { Box, Spinner } from '@chakra-ui/react';

export default function Loader() {
  // TODO :: Its a circular progress, we need to update it
  return (
    <Box
      position="fixed"
      left="0"
      top="0"
      w="100%"
      h="100vh"
      opacity="0.7"
      backgroundColor="white"
      zIndex="999999"
    >
      <Spinner
        color="positive"
        position="absolute"
        left="50%"
        top="50%"
        zIndex="999999"
        size="lg"
      />
    </Box>
  );
}
