import { Stack, Text, Button, Flex, Image, VStack, Box } from '@chakra-ui/react';
import { useEffect } from 'react';
import maintenance from '../assets/images/maintenance.svg';
import maintenance1 from '../assets/images/maintenance1.svg';
import Background from './Background';

export default function Maintenance() {
  useEffect(() => {
    document.title = 'Under maintenance';
  }, []);

  return (
    <Background showButton={false}>
      <VStack>
        <Stack
          spacing={6}
          w={['', 'full']}
          maxW="md"
          bg="white"
          boxShadow="lg"
          borderRadius="12px"
          p={6}
          mb={['20', '0']}
          mt={['40', '60']}
        >
          <Text lineHeight="28px" fontSize="20px" textColor="#1C1E23" align="center">
            We are currently performing some maintenance. We apologize for any inconvenience caused.
          </Text>

          <Button
            color="indigo.500"
            variant="link"
            onClick={() => {
              window.location.replace('https://support.macrometa.com');
            }}
          >
            Contact Support
          </Button>
        </Stack>
        <Flex justifyContent="space-between" margin="100px" flex={1}>
          <Box position="relative">
            <Image src={maintenance} verticalAlign="center" />
          </Box>
          <Box position="relative">
            <Image src={maintenance1} verticalAlign="center" />
          </Box>
        </Flex>
      </VStack>
    </Background>
  );
}
