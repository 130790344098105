import { Flex, VStack, Text, Box, Stack } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3';
import FeatureList, { FeatureListProps } from '../elements/FeatureList';
import useApi from '../hooks/useApi';
import ApiCallMethods from '../utils/constants/api';
import ApiEndPoints from '../utils/constants/endPoints';
import NavigationPages from '../utils/constants/NavigationPages';
import SignupLeftInformation from '../utils/constants/stringLiterals';
import { emailRegex, passwordRegex, populatePlacementUrl } from '../utils/helper/helperFunctions';
import { SignUpReqType } from '../utils/types/apiRequest.type';
import {
  InfoDataType,
  PlacementUrlDataType,
  RouteDataType,
  UserDataType,
} from '../utils/types/formValues.type';
import AlertMessage from '../components/AlertMessage';
import Background from '../components/Background';
import MailStatus from '../components/MailStatus';
import SignInSignupForm from '../components/SignInSignupForm';
import UIStrings from '../utils/constants/uIStrings';
import { SignUpMaintenanceContextType } from '../utils/types/maintenanceContext.type';
import SignUpMaintenanceContext from '../hooks/useSignUpMaintenanceContext';
import fetchUsingAxios from '../utils/service/api';

export default function SignUp() {
  // #region State and contexts
  const {
    EMAIL_ADDRESS,
    PASSWORD,
    BACKGROUND_HEADER_SIGN_UP,
    VALIDATION_TEXT: {
      PASSWORD_CANNOT_BE_EMPTY,
      VALID_ALIAS,
      EMAIL_CANNOT_BE_EMPTY,
      VALID_EMAIL,
      PASSWORD_FORM,
    },
    PROMOTIONAL_TEXT: {
      SIGN_UP: { HEADER_LABEL_ONE, HEADER_LABEL_TWO },
      LABEL_ONE,
      LABEL_TWO,
    },
    ATTRIBUTION: { MACROMETA },
    BUTTON_TEXT: { INTRO, LOG_IN },
    ERROR_TEXTS: { REGISTRATION_FAILED, CONFLICT },
  } = UIStrings;

  const [mailSentSuccess, setMailSentSuccess] = useState<boolean>();
  const [showError, setShowError] = useState<boolean>(false);
  const [errorResponse, setErrorResponse] = useState<string>('');
  const [placementUrlHostName, setPlacementUrlHostName] = useState<string>('');
  const [signUpPlacementUrl, setSignUpPlacementUrl] = useState<string>('');
  let { isRunningUnderParsec, parsecDomain } =
    useContext<SignUpMaintenanceContextType>(SignUpMaintenanceContext);

  const [userData, setUserData] = useState<UserDataType>({
    email: {
      value: '',
      error: false,
      alreadyValidated: false,
      helperText: '',
    },
    password: {
      value: '',
      error: false,
      alreadyValidated: false,
      helperText: '',
    },
    domain: {
      value: '',
      error: false,
      alreadyValidated: false,
      helperText: '',
    },
  });
  const [displayAlert, setDisplayAlert] = useState<'none' | 'flex'>('none');
  const [token, setToken] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const { response, getApiResult } = useApi();
  const history = useHistory();
  //#endregion

  //#region Effects
  useEffect(() => {
    loadReCaptcha(import.meta.env.VITE_GOOGLE_CAPTCHA_V3_SITE_KEY);
  }, []);

  useEffect(() => {
    if (response) {
      if (response?.isSuccess) {
        setMailSentSuccess(true);
        setDisplayAlert('none');
      } else {
        if (response?.status === 409 && response?.data?.message === CONFLICT) {
          const infoData: InfoDataType = {
            userAlreadyExist: true,
            resetPasswordSuccess: false,
            errorMessage: '',
          };
          history.replace({ pathname: '/', state: infoData });
          return;
        }
        if (response?.status === 302) {
          const routeData: RouteDataType = {
            userDataObject: userData,
            redirectedFromLogin: false,
            rememberMe: false,
            redirectedFromSignup: true,
            redirectedFromPasswordReset: false,
            redirectedFromReverification: false,
            placementUrl: signUpPlacementUrl,
          };
          history.replace({ pathname: NavigationPages.RECAPTCHA, state: routeData });
          return;
        }
        setDisplayAlert('flex');
      }
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, response]);
  //#endregion

  const checkIfValidInfoProvided = (): boolean => {
    if (userData.email.value.length === 0) {
      setUserData((userDataObject) => ({
        ...userDataObject,
        email: {
          error: true,
          helperText: EMAIL_CANNOT_BE_EMPTY,
          value: '',
          alreadyValidated: false,
        },
      }));
      return false;
    }
    if (isRunningUnderParsec && userData.domain?.value.length === 0) {
      setUserData((userDataObject) => ({
        ...userDataObject,
        domain: {
          error: true,
          helperText: VALID_ALIAS,
          value: '',
          alreadyValidated: false,
        },
      }));
      return false;
    }
    if (userData.password.value.length === 0) {
      setUserData((userDataObject) => ({
        ...userDataObject,
        password: {
          error: true,
          helperText: PASSWORD_CANNOT_BE_EMPTY,
          value: '',
          alreadyValidated: false,
        },
      }));
      return false;
    }

    return true;
  };

  const handleSignUp = async () => {
    // ToDo Handle attribution
    togglePlacementError(false);
    if (checkIfValidInfoProvided()) {
      setLoading(true);

      if (!isRunningUnderParsec) {
        await processSignUpRequest(ApiEndPoints.SIGN_UP);
        return;
      }

      const placementUrlHost = await getPlacementHostUrl();
      if (!placementUrlHost) {
        togglePlacementError(true, REGISTRATION_FAILED);
        return;
      }

      setPlacementUrlHostName(placementUrlHost);
      const signUpUrl = populatePlacementUrl(placementUrlHost, ApiEndPoints.WRTParsec.SIGN_UP);
      setSignUpPlacementUrl(signUpUrl);
      await processSignUpRequest(signUpUrl);
      return;
    }
  };

  const processSignUpRequest = async (endPoint: string) => {
    const signUpReq: SignUpReqType = {
      email: userData.email.value,
      password: userData.password.value,
      attribution: MACROMETA,
      domain: userData.domain?.value,
      verifyToken: token,
      secret: import.meta.env.VITE_GOOGLE_CAPTCHA_V3_SECRET_KEY,
    };
    await getApiResult(ApiCallMethods.POST, endPoint, signUpReq);
  };

  const getPlacementHostUrl = async () => {
    try {
      const placementUrl = `https://${parsecDomain}${ApiEndPoints.PLACEMENT_SUB_URL}`;
      const placementResponse = await fetchUsingAxios(ApiCallMethods.GET, placementUrl);
      if (
        placementResponse &&
        placementResponse.data &&
        placementResponse.data.userRegistrationURL
      ) {
        return placementResponse.data.userRegistrationURL;
      }
    } catch (error) {
      setLoading(false);
    }
  };

  //#region Validations
  const validateEmailPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const { id, value } = event.target;
    switch (id) {
      case 'email':
        checkIsValidEmail(value);
        break;
      case 'password':
        checkIsValidPassword(value);
        break;
      case 'domain':
        checkIsValidDomain(value);
        break;
      default:
        break;
    }
  };
  const checkIsValidEmail = (value: string) => {
    if (value.trim() && !emailRegex.test(value)) {
      setUserData((userDataObject) => ({
        ...userDataObject,
        email: {
          error: true,
          helperText: VALID_EMAIL,
          value: value,
          alreadyValidated: false,
        },
      }));
    } else {
      setUserData((userDataObject) => ({
        ...userDataObject,
        email: {
          error: false,
          alreadyValidated: true,
          helperText: '',
          value: value,
        },
      }));
    }
  };
  const checkIsValidPassword = (value: string) => {
    if (value.trim() && value.length === 0) {
      setUserData((userDataObject) => ({
        ...userDataObject,
        password: {
          error: true,
          helperText: PASSWORD_CANNOT_BE_EMPTY,
          value: value,
          alreadyValidated: false,
        },
      }));
    } else if (!passwordRegex.test(value)) {
      setUserData((userDataObject) => ({
        ...userDataObject,
        password: {
          error: true,
          helperText: PASSWORD_FORM,
          value: value,
          alreadyValidated: false,
        },
      }));
    } else {
      setUserData((userDataObject) => ({
        ...userDataObject,
        password: {
          error: false,
          helperText: '',
          alreadyValidated: true,
          value: value,
        },
      }));
    }
  };

  const checkIsValidDomain = (value: string) => {
    if (!value.trim()) {
      setUserData((userDataObject) => ({
        ...userDataObject,
        domain: {
          error: true,
          helperText: VALID_ALIAS,
          value: value,
          alreadyValidated: false,
        },
      }));
    } else {
      setUserData((userDataObject) => ({
        ...userDataObject,
        domain: {
          error: false,
          alreadyValidated: true,
          helperText: '',
          value: value,
        },
      }));
    }
  };
  //#endregion

  //#region events
  const togglePlacementError = (showError: boolean, errorMessage: string = '') => {
    if (showError) {
      setShowError(true);
      setErrorResponse(errorMessage);
      setDisplayAlert('flex');
      return;
    }
    setShowError(false);
    setErrorResponse(errorMessage);
    setDisplayAlert('none');
  };

  const handleOnClose = () => {
    setDisplayAlert('none');
  };

  const onFormLabel1Focus = () => {
    setDisplayAlert('none');
  };

  const verifyCallback = (reCaptchaToken: string) => {
    setToken(reCaptchaToken);
  };

  const onForgotAliasClicked = () => {
    const email = userData.email.value;
    if (!email.trim() || (email.trim() && !emailRegex.test(email))) {
      setUserData((userDataObject) => ({
        ...userDataObject,
        email: {
          error: true,
          helperText: VALID_EMAIL,
          value: email,
          alreadyValidated: false,
        },
      }));
    }
  };

  const onReverificationClicked = () => {
    // TODO uncomment once parsec is back in picture
    // let reverificationUrl = ApiEndPoints.RESEND_VERIFICATION_MAIL;
    // if (isRunningUnderParsec) {
    //   reverificationUrl = populatePlacementUrl(
    //     placementUrlHostName,
    //     ApiEndPoints.WRTParsec.RESEND_VERIFICATION,
    //   );
    // }
    // 
    // const placementUrlData: PlacementUrlDataType = {
    //   placementUrl: reverificationUrl,
    // };
    // history.push({ pathname: NavigationPages.RE_VERIFICATION, state: placementUrlData });
    history.push(NavigationPages.RE_VERIFICATION)
  };
  //#endregion

  return (
    <div>
      {!mailSentSuccess ? (
        <Background
          headerLabel={BACKGROUND_HEADER_SIGN_UP}
          buttonText={LOG_IN}
          navigationLink={NavigationPages.LOGIN}
          isSignUp
        >
          <Flex
            flex={1}
            minH="100vh"
            minW="100vw"
            direction={{ base: 'column-reverse', md: 'row' }}
          >
            <Flex
              flex={1}
              direction={{ base: 'column', md: 'row' }}
              bgGradient="linear( #6767E6 26.9%, #E67CA8 180.47%)"
              justify="center"
              align="center"
            >
              <VStack
                bg="transparent"
                maxW="xl"
                ml={{ base: '3em', md: '3em', lg: '6em' }}
                mr={{ base: '3em', md: '3em', lg: '6em' }}
                mt={{ base: '3em', md: '4em' }}
                mb={['3em']}
              >
                <Text
                  color="white"
                  fontWeight="700"
                  lineHeight="56px"
                  letterSpacing="-1"
                  fontSize="5xl"
                  pr="4%"
                  mb="4px"
                >
                  {HEADER_LABEL_ONE}
                </Text>
                <Text fontWeight="300" lineHeight="40px" fontSize="3xl" color="indigo.50">
                  {HEADER_LABEL_TWO}
                </Text>
                {SignupLeftInformation.map((element: FeatureListProps, index: number) => (
                  <FeatureList
                    key={`FeatureItem-${index.toString()}`}
                    heading={element.heading}
                    subHeading={element.subHeading}
                  />
                ))}
              </VStack>
            </Flex>
            <Flex
              flex={1}
              direction={{ base: 'column', md: 'row' }}
              ml={15}
              mr={15}
              align="center"
              justify="center"
            >
              <Stack
                spacing={6}
                w={['100', 'full']}
                maxW="md"
                p={6}
                mb={['10', '0']}
                mt={['20', '35', '3']}
              >
                <Box rounded="lg" maxW={['sm', 'md']}>
                  <Stack align="start" justify="space-between" pb={5}>
                    <Text
                      noOfLines={[2, 1]}
                      fontSize="2xl"
                      fontWeight="700"
                      lineHeight="32px"
                      color="gray.800"
                    >
                      {LABEL_ONE}
                    </Text>
                    <Text
                      noOfLines={[2, 1]}
                      fontSize="sm"
                      fontWeight="400"
                      lineHeight="20px"
                      color="gray.600"
                    >
                      {LABEL_TWO}
                    </Text>
                  </Stack>
                  {response?.isSuccess === false && (
                    <AlertMessage
                      alertType="error"
                      alertMessage={response?.data?.message?.toString()}
                      displayAlert={displayAlert}
                      onClose={handleOnClose}
                      isOpen={response?.isSuccess === false}
                    />
                  )}
                  {showError && (
                    <AlertMessage
                      alertType="error"
                      alertMessage={errorResponse}
                      displayAlert={displayAlert}
                      onClose={handleOnClose}
                      isOpen
                    />
                  )}
                  <SignInSignupForm
                    formLabel1={EMAIL_ADDRESS}
                    isUnderParsec={isRunningUnderParsec}
                    subDomain={parsecDomain}
                    formLabel2={PASSWORD}
                    validateFormEntry={validateEmailPassword}
                    isLoading={loading}
                    handleClick={handleSignUp}
                    showForgotPasswordLink={false}
                    showCheckbox={false}
                    userData={userData}
                    isSignUp
                    buttonLabel={INTRO}
                    onFormLabel1Focus={onFormLabel1Focus}
                  />
                </Box>
              </Stack>
            </Flex>
          </Flex>
          <ReCaptcha
            sitekey={import.meta.env.VITE_GOOGLE_CAPTCHA_V3_SITE_KEY}
            action="signup"
            verifyCallback={verifyCallback}
          />
        </Background>
      ) : (
        <MailStatus isSignUp={true} handleClick={onReverificationClicked} />
      )}
    </div>
  );
}
