import axios, { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import ApiCallMethods from '../utils/constants/api';
import fetchUsingAxios from '../utils/service/api';
import { ApiResponse } from '../utils/types/apiResponse.type';

const useApi = () => {
  const [response, setResponse] = useState<ApiResponse>();

  const getApiResult = useCallback(async (method: ApiCallMethods, url: string, data?: any) => {
    try {
      const resp = await fetchUsingAxios(method, url, data);
      setResponse((axiosResponseObject) => ({
        ...axiosResponseObject,
        status: resp?.status || 200,
        statusText: resp?.statusText,
        isSuccess: true,
        data: resp?.data,
      }));
    } catch (error) {
      if (axios.isAxiosError(error) && error.message !== 'Network Error') {
        const err = error.response;
        const errorData = err?.data as AxiosError;

        setResponse((axiosErrorObject) => ({
          ...axiosErrorObject,
          status: err?.status || 500,
          statusText: err?.statusText,
          isSuccess: false,
          data: {
            message: errorData.message,
            status: Number(errorData.status),
          },
        }));
      } else {
        setResponse((axiosErrorObject) => ({
          ...axiosErrorObject,
          status: 500,
          statusText: 'Something went wrong!',
          isSuccess: false,
          data: {
            message: 'Something went wrong!',
            status: 500,
          },
        }));
      }
    }
  }, []);
  return { response, getApiResult };
};

export default useApi;
