/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-children-prop */
import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import posthog from 'posthog-js';
import Maintenance from './components/Maintenance';
import useApi from './hooks/useApi';
import ForgotPasswordForm from './pages/ForgotPassword';
import Login from './pages/LogIn';
import ResetPasswordForm from './pages/ResetPassword';
import ReVerification from './pages/ReVerification';
import SignUp from './pages/Sign-Up';
import VerifyUpdateEmail from './pages/VerifyUpdateEmail';
import ApiCallMethods from './utils/constants/api';
import ApiEndPoints from './utils/constants/endPoints';
import NavigationPages from './utils/constants/NavigationPages';
import { FrontEndConfigResponse } from './utils/types/apiResponse.type';
import SignUpMaintenanceContext from './hooks/useSignUpMaintenanceContext';
import SignUpBeta from './components/SignUpBeta';
import ErrorPage from './components/Error';
import GoogleCaptchaForm from './pages/GoogleCaptchaForm';
import Verify from './pages/Verify';
import CreateUser from './pages/CreateUser';
import UpgradeScale from './pages/UpgradeScale';
import fetchUsingAxios from './utils/service/api';
import UIStrings from './utils/constants/uIStrings';
import Domain from './pages/Domain';
import DomainRecovery from './pages/DomainRecovery';

function App() {
  const { response, getApiResult } = useApi();
  const [isUnderMaintenance, setIsUnderMaintenance] = useState<boolean>(false);
  const [signUpMaintainanceLink, setSignUpMaintainanceLink] = useState<string>('');
  const [isConfigError, setConfigError] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRunningUnderParsec, setIsRunningUnderParsec] = useState<boolean>(false);
  const [parsecDomain, setParsecDomain] = useState<string>('');
  const [isPrivateGdn, setIsPrivateGdn] = useState<boolean>(false);

  const initAuth = async () => {
    setIsLoading(true);
    // Todo :: commented this since parsec is out of the recent release
    // await getHealthzStatus();
    // if (!isRunningUnderParsec) {
    //   getApiResult(ApiCallMethods.GET, `${ApiEndPoints.FRONTEND_CONFIG}`);
    // }
    getApiResult(ApiCallMethods.GET, `${ApiEndPoints.FRONTEND_CONFIG}`);
    setIsLoading(false);
  };

  //check if its running under parsec
  const getHealthzStatus = useCallback(async () => {
    try {
      const healtzStatus = await fetchUsingAxios(ApiCallMethods.GET, ApiEndPoints.HEALTHZ);
      if (healtzStatus && healtzStatus.data) {
        const key = Object.keys(healtzStatus?.data);
        if (key.length > 0) {
          if (key[0] === UIStrings.PARSEC.PARSEC_IDENTIFIER) {
            setIsRunningUnderParsec(true);
            setIsUnderMaintenance(false);
            const domain = healtzStatus.data['parsec-engine'].domain;
            setParsecDomain(domain);
            return;
          }
        }
        setIsRunningUnderParsec(false);
      }
    } catch (error: any) {
      setIsRunningUnderParsec(false);
    }
  }, []);

  useEffect(() => {
    initAuth();
  }, []);

  useEffect(() => {
    if (response && response?.isSuccess && response?.data) {
      const {
        signupMaintainance,
        signupMaintainanceLink,
        isPrivateGdn,
        posthogAPIKey,
        posthogServerURL,
      } = response.data;
      const result: FrontEndConfigResponse = {
        signupMaintainance,
        signupMaintainanceLink,
        isPrivateGdn,
        posthogAPIKey,
        posthogServerURL,
      };
      if (result.signupMaintainanceLink && result.signupMaintainance === true) {
        setIsUnderMaintenance(result.signupMaintainance);
        setSignUpMaintainanceLink(result.signupMaintainanceLink);
      } else {
        setIsUnderMaintenance(result.signupMaintainance);

        if (posthog && result.posthogAPIKey && result.posthogServerURL) {
          posthog.init(result.posthogAPIKey, { api_host: result.posthogServerURL });
        }
      }

      setIsPrivateGdn(result.isPrivateGdn);
    }
    if (
      response &&
      response.status >= 400 &&
      response.status <= 600 &&
      !window.location.href.includes('maintenance')
    ) {
      setConfigError(true);
      window.location.replace(`${window.location.href}maintenance`);
    }
    setIsLoading(false);
  }, [response]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <SignUpMaintenanceContext.Provider
      value={{
        isUnderMaintenance,
        signUpMaintainanceLink,
        isConfigError,
        isLoading,
        isRunningUnderParsec,
        parsecDomain,
        isPrivateGdn,
      }}
    >
      <Router>
        <>
          <Switch>
            {isRunningUnderParsec ? (
              <Route path={NavigationPages.ROOT} component={Domain} exact />
            ) : (
              <Route path={NavigationPages.ROOT} component={Login} exact />
            )}
            {isRunningUnderParsec && (
              <Route path={NavigationPages.DOMAIN_RECOVERY} component={DomainRecovery} exact />
            )}
            {!isUnderMaintenance && !isPrivateGdn && (
              <Route path={NavigationPages.SIGN_UP} component={SignUp} exact />
            )}
            <Route path={NavigationPages.FORGOT_PASSWORD} component={ForgotPasswordForm} exact />
            <Route path={NavigationPages.RESET_PASSWORD} component={ResetPasswordForm} exact />
            <Route path={NavigationPages.RE_VERIFICATION} component={ReVerification} exact />
            <Route path={NavigationPages.VERIFY_EMAIL} component={VerifyUpdateEmail} exact />
            <Route path={NavigationPages.RECAPTCHA} component={GoogleCaptchaForm} exact />
            <Route path={NavigationPages.VERIFY} component={Verify} exact />
            <Route path={NavigationPages.MAINTENANCE} component={Maintenance} exact />
            <Route path={NavigationPages.ERROR} component={ErrorPage} exact />
            <Route path={NavigationPages.INVITE_USER} component={CreateUser} exact />
            <Route path={NavigationPages.UPGRADE_SCALE} component={UpgradeScale} exact />
            {isUnderMaintenance && (
              <Route path={NavigationPages.SIGN_UP} component={SignUpBeta} exact />
            )}
            <Route path="*" component={ErrorPage} />
          </Switch>
        </>
      </Router>
    </SignUpMaintenanceContext.Provider>
  );
}

export default App;
