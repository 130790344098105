import { Stack, Text, Button } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import Background from './Background';

export default function ErrorPage() {
  const history = useHistory();
  return (
    <Background showButton={false}>
      <Stack
        spacing={6}
        w={['', 'full']}
        maxW="md"
        bg="white"
        boxShadow="lg"
        borderRadius="12px"
        p={6}
        mt={['40', '60']}
        alignItems="center"
      >
        <Text
          lineHeight="28px"
          fontSize="21px"
          fontWeight={600}
          textAlign="center"
          textColor="#1C1E23"
        >
          Something went wrong!
        </Text>

        <Button
          color="indigo.500"
          variant="link"
          onClick={() => {
            history.replace('/');
          }}
        >
          Go Back
        </Button>
      </Stack>
    </Background>
  );
}
