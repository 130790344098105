import { HStack, Text, Image } from '@chakra-ui/react';
import LightiningLogo from '../assets/images/lightening.svg';

export type FeatureListProps = {
  heading: string;
  subHeading: string;
};

export default function FeatureList({ heading, subHeading }: FeatureListProps) {
  return (
    <>
      <HStack justifyItems="start" minW="100%" pt="20px" mb="8px">
        <Image src={LightiningLogo} />
        <Text
          textColor="#F0F0FD"
          fontSize="21px"
          fontStyle="normal"
          fontWeight="600"
          lineHeight="24px"
        >
          {heading}
        </Text>
      </HStack>
      <Text
        textColor="#F0F0FD"
        fontSize="15px"
        fontStyle="normal"
        fontWeight="400"
        lineHeight="24px"
        pl="25px"
        pr="105px"
      >
        {subHeading}
      </Text>
    </>
  );
}
