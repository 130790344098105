import { Button, Center, Stack, Text, HStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';
import useApi from '../hooks/useApi';
import ApiCallMethods from '../utils/constants/api';
import ApiEndPoints from '../utils/constants/endPoints';
import { emailRegex } from '../utils/helper/helperFunctions';
import { ResendVerificationLinkReqType } from '../utils/types/apiRequest.type';
import {
  ReverificationDataType,
  ReVerifyUserDataType,
  RouteDataType,
  UserDataType,
  PlacementUrlDataType,
} from '../utils/types/formValues.type';
import Background from '../components/Background';
import MailStatus from '../components/MailStatus';
import ReverificationForm from '../components/ReVerificationForm';
import UIStrings from '../utils/constants/uIStrings';
import NavigationPages from '../utils/constants/NavigationPages';
import AlertMessage from '../components/AlertMessage';

export default function ReVerification() {
  // #region State Initialisations
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [displayAlert, setDisplayAlert] = useState<'none' | 'flex'>('none');
  const [isExhausted, setIsExhausted] = useState(false);
  const [token, setToken] = useState<string | undefined>();
  const { response, getApiResult } = useApi();
  const [signupSuccess, setSignupSuccess] = useState<boolean>(false);
  const [errorResponse, setErrorResponse] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');
  const [userData, setUserData] = useState<ReverificationDataType>({
    email: {
      value: '',
      error: false,
      alreadyValidated: false,
      helperText: '',
    },
    showEmailInput: {
      value: false,
    },
  });
  // #endregion

  // #region Effects
  useEffect(() => {
    if (location.state) {
      const routeData = location.state as ReVerifyUserDataType;
      if (routeData && routeData.email) {
        setUserData((userDataObject) => ({
          ...userDataObject,
          email: {
            value: routeData.email,
            error: false,
            helperText: '',
            alreadyValidated: false,
          },
        }));
      }
      if (routeData.areAttemptsExhausted) {
        setIsExhausted(true);
      }
      toggleCustomError(true, routeData.error);
      //todo uncomment and merge with above when parsec comes into picture again
      // const placementUrlData = location.state as PlacementUrlDataType;
      // if (!placementUrlData || !placementUrlData.placementUrl) {
      //   history.goBack();
      // }
      // setUrl(placementUrlData.placementUrl);
    }
  }, [history, location.state]);

  useEffect(() => {
    loadReCaptcha(import.meta.env.VITE_GOOGLE_CAPTCHA_V3_SITE_KEY);
  }, []);

  useEffect(() => {
    toggleBtnState();
  }, [userData?.email]);

  useEffect(() => {
    if (response) {
      setLoading(false);
      if (response?.isSuccess) {
        setSignupSuccess(true);
      } else {
        // eslint-disable-next-line no-lonely-if
        if (response?.status === 302) {
          handleCaptcha();
          return;
        }
        if (response?.status === 403) {
          setDisplayAlert('flex');
          setButtonDisable(true);
          setIsExhausted(true);
          return;
        }
        history.replace({ pathname: NavigationPages.ERROR });
      }
    }
  }, [history, response]);
  // #endregion

  // #region Validations
  const toggleBtnState = () => {
    if (
      userData?.email?.value &&
      emailRegex.test(userData?.email?.value) &&
      isExhausted === false
    ) {
      setButtonDisable(false);
      return;
    }
    setButtonDisable(true);
  };
  const toggleCustomError = (showError: boolean, message: string = '') => {
    if (showError) {
      setShowError(true);
      setErrorResponse(message);
      setDisplayAlert('flex');
      return;
    }
    setShowError(false);
    setErrorResponse('');
    setDisplayAlert('none');
  };
  const handleOnClose = () => {
    setDisplayAlert('none');
  };

  const inputValidation = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    if (event.target.id === 'email') {
      if (!emailRegex.test(event.target.value)) {
        setUserData((userDataObject) => ({
          ...userDataObject,
          email: {
            error: true,
            helperText: UIStrings.VALIDATION_TEXT.VALID_EMAIL,
            value: event.target.value,
            alreadyValidated: false,
          },
        }));
        setButtonDisable(true);
      } else {
        setUserData((userDataObject) => ({
          ...userDataObject,
          email: {
            error: false,
            alreadyValidated: true,
            helperText: '',
            value: event.target.value,
          },
        }));
        setButtonDisable(false);
      }
    }
  };
  // #endregion

  // #region Event Handlers
  const handleCaptcha = () => {
    const dataObject: UserDataType = {
      email: {
        value: userData.email.value,
        helperText: '',
        alreadyValidated: true,
        error: false,
      },
      password: {
        value: '',
        error: false,
        alreadyValidated: true,
        helperText: '',
      },
      domain: {
        value: '',
        error: false,
        alreadyValidated: true,
        helperText: '',
      },
    };
    const routeData: RouteDataType = {
      userDataObject: dataObject,
      rememberMe: false,
      redirectedFromLogin: false,
      redirectedFromSignup: false,
      redirectedFromPasswordReset: false,
      redirectedFromReverification: true,
    };

    history.replace({ pathname: NavigationPages.RECAPTCHA, state: routeData });
  };
  const handleBlur = () => {
    if (userData.email.value.length === 0) {
      setUserData((userDataObject) => ({
        ...userDataObject,
        email: {
          value: '',
          error: false,
          helperText: '',
          alreadyValidated: false,
        },
      }));
    }
  };

  const handleResendVerificationLink = () => {
    toggleCustomError(false);
    if (userData.email.value) {
      setButtonDisable(true);
      setLoading(true);
      const resendVerificaitonReq: ResendVerificationLinkReqType = {
        email: userData.email.value.toLowerCase(),
        attribution: UIStrings.ATTRIBUTION.MACROMETA,
        verifyToken: token,
        secret: import.meta.env.VITE_GOOGLE_CAPTCHA_V3_SECRET_KEY,
      };
      // Todo update url with placement url once parsec in back again
      // getApiResult(ApiCallMethods.POST, url, resendVerificaitonReq);
      getApiResult(
        ApiCallMethods.POST,
        ApiEndPoints.RESEND_VERIFICATION_MAIL,
        resendVerificaitonReq,
      );
    } else {
      setUserData((userDataObject) => ({
        ...userDataObject,
        email: {
          error: true,
          helperText: UIStrings.VALIDATION_TEXT.VALID_EMAIL,
          value: '',
          alreadyValidated: false,
        },
      }));
    }
  };
  // #endregion

  const verifyCallback = (reCaptchaToken: string) => {
    setToken(reCaptchaToken);
  };

  return (
    <div>
      {!signupSuccess ? (
        <Background
          headerLabel={UIStrings.BACKGROUND_HEADER}
          buttonText={UIStrings.BUTTON_TEXT.SIGN_UP}
          navigationLink={NavigationPages.SIGN_UP}
        >
          <Stack
            spacing={4}
            w={['', 'full']}
            maxW="md"
            bg="white"
            borderRadius="12px"
            boxShadow="lg"
            p={6}
            mt={['40', '60']}
          >
            <Text
              lineHeight="28px"
              fontStyle="normal"
              fontSize="21px"
              fontWeight={600}
              textColor="#1C1E23"
            >
              {UIStrings.RESEND_VER_LINK}
            </Text>{' '}
            {response?.isSuccess === false && (
              <AlertMessage
                alertType="error"
                alertMessage={response?.data?.message?.toString()}
                displayAlert={displayAlert}
                onClose={handleOnClose}
                isOpen={response?.isSuccess === false}
              />
            )}
            {showError && (
              <AlertMessage
                alertType="error"
                alertMessage={errorResponse}
                displayAlert={displayAlert}
                onClose={handleOnClose}
                isOpen
              />
            )}
            <ReverificationForm
              formLabelerror={userData.email.error}
              formLabelhelperText={userData.email.helperText}
              prefillValue={userData.email.value}
              buttonLabel={UIStrings.BUTTON_TEXT.RESEND}
              validateEmail={inputValidation}
              isLoading={loading}
              handleClick={handleResendVerificationLink}
              onBlur={handleBlur}
              buttonDisabled={buttonDisable}
            />
            {isExhausted && (
              <Center fontSize="sd" fontWeight="light">
                <HStack>
                  <Text fontWeight={400} fontSize="14px">
                    {UIStrings.BACKGROUND_HEADER_SIGN_UP}
                  </Text>
                  <Button
                    variant="link"
                    fontWeight={400}
                    fontSize="14px"
                    onClick={() => history.replace('/')}
                  >
                    {UIStrings.BUTTON_TEXT.LOG_IN}
                  </Button>
                </HStack>
              </Center>
            )}
          </Stack>
          <ReCaptcha
            sitekey={import.meta.env.VITE_GOOGLE_CAPTCHA_V3_SITE_KEY}
            action="login"
            verifyCallback={verifyCallback}
          />
        </Background>
      ) : (
        <MailStatus
          isSignUp={true}
          handleClick={() => {
            setSignupSuccess(false);
            toggleBtnState();
          }}
        />
      )}
    </div>
  );
}
