export enum NavigationWorkFlowEnum {
  PRIVATE_GDN,
}

const UIStrings = {
  LOG_IN: 'Log in',
  DOMAIN: 'Domain',
  DOMAIN_RECOVERY: 'Domain recovery',
  RECOVERY_TITLE: 'Recovery details sent',
  RECOVERY_SUBTITLE: 'Check your inbox for the recovery instructions.',
  MACROMETA: '© Macrometa',
  TERMS: ' Terms',
  PRIVACY_POLICY: 'Privacy Policy',
  FORGOT_PASSWORD: 'Forgot your password?',
  FORGOT_ALIAS: 'Forgot your domain?',
  AWAITING_VERIFICATION: 'Awaiting verification',
  MAIL_STATUS_HEADER: 'Check your inbox for a password reset link.',
  MAIL_STATUS_HEADER_FOR_VERIFY: 'Check your inbox for an account verification link.',
  MAIL_SUBTEXT: "Didn't receive an email?",
  PLACE_HOLDER_TEXT: 'developer@macrometa.com',
  BACKGROUND_HEADER: "Don't have an account?",
  BACKGROUND_HEADER_SIGN_UP: 'Already have an account?',
  PASSWORD_RESET: 'Reset password',
  EMAIL_ADDRESS: 'Email Address',
  PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Password Confirmation',
  REMEMBER_ME: 'Remember me',
  RESEND_VER_LINK: 'Resend Verification Link',
  TERMS_POLICY: ' By signing up, you accept our terms of service and privacy policy.',
  ALL_SET: 'You are all set!',
  SETTING_ACCOUNT: 'Setting up your account',
  UPGRADE_COMPLETE: 'Upgrade complete',
  UPGRADE_SUBTEXT:
    'This account has been upgraded to the Scale tier and all users, data and resources have been migrated successfully.',

  BUTTON_TEXT: {
    RESEND: 'Resend',
    SIGN_UP: 'Sign up',
    SUBMIT: 'Submit',
    LOG_IN: 'Log in',
    INTRO: "Let's get started!",
    TROUBLESHOOT: 'Troubleshoot',
    NEW_ACCOUNT: 'Go to your new account',
    NEXT: 'Next',
  },

  ERROR_TEXTS: {
    SOMETHING_WENT_WRONG: 'Something went wrong',
    DOMAIN_NOT_AVAILABLE: 'Please check the domain provided! It is either unreachable or not found',
    REGISTRATION_FAILED: 'Unable to register at the moment',
    CONFLICT: 'Conflict',
  },

  VALIDATION_TEXT: {
    VALID_EMAIL: 'Enter a valid Email',
    VALID_ALIAS: 'Alias cannot be empty',
    INVALID_DOMAIN: 'Domain cannot be empty',
    VALID_PASSWORD: 'Enter a valid Password',
    PASSWORD_FORM:
      'Passwords require a minimum of 8 characters, a number, a capital and lowercase letter, and one of the following special characters: !@#$%^&*',
    PASS_CONFIRM_NOT_MATCH: "Password and confirm password don't match",
    EMAIL_CANNOT_BE_EMPTY: 'Email cannot be empty',
    PASSWORD_CANNOT_BE_EMPTY: 'Password cannot be empty',
    CONFIRM_PASSWORD_CANNOT_BE_EMPTY: 'Password confirmation cannot be empty',
    USER_ALREADY_SIGNED_UP:
      'A user has already signed up with the given email. Use the regular sign-in',
    VERIFY_TOKEN_EXPIRED: 'Verification token expired. Resend link to try again',
    UNVERIFIED_USER_EXIST:
      'Email verification required. Please check your inbox for a verification link, or resend to try again',
    DISPLAY_NAME_MAX_LENGTH_EXCEED: 'May not exceed 80 characters',
  },

  ATTRIBUTION: {
    MACROMETA: 'Macrometa',
  },

  PROMOTIONAL_TEXT: {
    LABEL_ONE: 'Create a free developer account',
    LABEL_TWO: 'Sign up for instant access to our Global Data Network',
    SIGN_UP: {
      HEADER_LABEL_ONE: 'The Developer Platform for the Edge and Beyond',
      HEADER_LABEL_TWO:
        ' Build real-time, globally distributed apps and APIs in minutes – not months',
    },
  },

  STATIC_URLS: {
    TERMS: 'https://www.macrometa.com/terms-of-service',
    PRIVACY: 'https://www.macrometa.com/privacy-policy',
    TROUBLESHOOT: 'https://macrometa.com/docs/references/troubleshooting',
    MACROMETA: 'https://www.macrometa.com',
  },

  INVITE_USER: {
    LINK_EXPIRED: 'Invite link expired',
    LINK_TEXT: 'Your invite link has expired after 24 hours',
    SUPPORT: 'Please contact your admin for assistance',
    DISPLAY_NAME: 'Display Name',
    PRIVACY_POLICY: 'privacy policy.',
    TERMS: 'terms of service',
    SIGNING_UP: 'By signing up, you accept our',
  },

  URL_MAP: {
    AUTH_PAAS_URL: 'auth.paas.macrometa.io',
    AUTH_ENG_URL: 'auth.eng.macrometa.io',
    GDN_URL: 'https://gdn.paas.macrometa.io',
    DEFAULT_FED: 'https://anurag.eng.macrometa.io',
  },

  PARSEC: {
    PARSEC_IDENTIFIER: 'parsec-engine',
  },

  SESSION_CONSTANTS: {
    ACCESS_TOKEN: 'access_token',
    REMEMBER_ME: 'rememberMe',
    HOST_URL: 'hostURL',
  },
};

export default UIStrings;

export enum CookiesAgeEnum {
  REMEMBER_ME_COOKIES_AGE = 8 * 60 * 60 * 1000,
  NON_REMEMBER_ME_COOKIES_AGE = 10 * 60 * 1000,
}
