import '@fontsource/source-code-pro';

export default {
  fonts: {
    body: `Averta, sans-serif`,
    heading: `Averta, sans-serif`,
    mono: `"Source Code Pro", monospace`,
  },
  
  fontSizes: {
    xs: '0.75rem', // 12px
    sm: '0.875rem', // 14px
    md: '0.9375rem', // 15px
    lg: '1rem', // 16px
    xl: '1.125rem', // 18px
    '2xl': '1.3125rem', // 21px
    '3xl': '1.6875rem', // 27px
    '4xl': '2.25rem', // 36px
    '5xl': '2.75rem', // 44px
    '6xl': '3.75rem', // 60px
  },

  sizes: {
    25: '6.25rem',
    37: '9.375rem',
    49: '12.5rem',
    81.5: '21.875rem',
    97.5: '25.75rem',
  },
};
