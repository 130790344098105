export const colors = {
  black: '#0B0C0E',
  white: '#FFFFFF',
  whiteTransp: 'rgba(255, 255, 255, 0.85)',
  gray: {
    50: '#F1F2F4',
    100: '#E2E4E8',
    200: '#C5C8D1',
    300: '#A9ADB9',
    400: '#8C91A2',
    500: '#6F768B',
    600: '#535968',
    700: '#383B46',
    800: '#1C1E23',
    900: '#0B0C0E',
  },
  blue: {
    50: '#EAF2FC',
    100: '#D5E3F7',
    200: '#ABC7EF',
    300: '#80ACE8',
    400: '#5690E0',
    500: '#2C74D8',
    600: '#2157A2',
    700: '#163A6C',
    800: '#0B1D36',
    900: '#040C16',
  },
  indigo: {
    50: '#F0F0FD',
    100: '#E1E1FA',
    200: '#C2C2F5',
    300: '#A4A4F0',
    400: '#8585EB',
    500: '#6767E6',
    600: '#4D4DAD',
    700: '#343473',
    800: '#1A1A3A',
    900: '#0A0A17',
  },
  purple: {
    50: '#F5EFFB',
    100: '#EADDF7',
    200: '#D5BBEF',
    300: '#C09AE6',
    400: '#AB78DE',
    500: '#9656D6',
    600: '#7141A1',
    700: '#4B2B6B',
    800: '#261636',
    900: '#0F0915',
  },
  magenta: {
    50: '#FBEAF1',
    100: '#F7D3E2',
    200: '#EFA7C5',
    300: '#E67CA8',
    400: '#DE508B',
    500: '#D6246E',
    600: '#A11B53',
    700: '#6B1237',
    800: '#36091C',
    900: '#15040B',
  },
  red: {
    50: '#FCE9E9',
    100: '#F8D1D1',
    200: '#F2A4A4',
    300: '#EB7676',
    400: '#E54949',
    500: '#DE1B1B',
    600: '#A71414',
    700: '#6F0E0E',
    800: '#380707',
    900: '#160303',
  },
  orange: {
    50: '#FCF1E6',
    100: '#F9E1CC',
    200: '#F3C399',
    300: '#ECA566',
    400: '#E68733',
    500: '#E06900',
    600: '#A84F00',
    700: '#703500',
    800: '#381A00',
    900: '#160B00',
  },
};

export const macrometaLightThemeColors = {
  primary: {
    ...colors.indigo,
  },
  neutral: {
    ...colors.gray,
  },
};
