const inputStyles = {
  Input: {
    baseStyle: {
      fontSize: '14px',
      borderRadius: '4px',
      bg: 'red',
    },
  },
};

export default inputStyles;
