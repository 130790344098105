import React from 'react';
import { SignUpMaintenanceContextType } from '../utils/types/maintenanceContext.type';

const SignUpMaintenanceContext = React.createContext<SignUpMaintenanceContextType>({
  isUnderMaintenance: false,
  signUpMaintainanceLink: '',
  isConfigError: false,
  isLoading: false,
  isPrivateGdn: false,
  isRunningUnderParsec: false,
  parsecDomain: '',
});

export default SignUpMaintenanceContext;
