import { Button, Heading, Stack,Text} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Background from "../components/Background";
import UIStrings from "../utils/constants/uIStrings";
import { sanitizeUrl } from "../utils/helper/helperFunctions";
import { UpgradeScaleDataType } from "../utils/types/formValues.type";

export default function UpgradeScale() {
  const location = useLocation();
  const history = useHistory();
  const [url, setUrl] = useState<string>('');
  
  useEffect(() => {
    if (location.state) {
      const upgradeScaleDataType = location.state as UpgradeScaleDataType;
      if (!upgradeScaleDataType || !upgradeScaleDataType.urlToNavigate) {
        history.push('/');
      }
      else if(upgradeScaleDataType && upgradeScaleDataType.urlToNavigate){
        const cleanedUrl = sanitizeUrl(upgradeScaleDataType.urlToNavigate);
        setUrl(cleanedUrl);
      }
    }
  }, [history, location.state]);
  
return (
    <Background showButton={false}>
      <Stack
        spacing={6}
        w={['', 'full']}
        maxW="md"
        bg="white"
        boxShadow="lg"
        borderRadius="12px"
        p={6}
        mt={['40', '60']}
        alignItems="center"
      >
        <Heading fontSize="2xl" fontStyle="normal" fontWeight="600" lineHeight="28px">
        {UIStrings.UPGRADE_COMPLETE}
        </Heading>
        <Text lineHeight="20px" fontSize="sm" textColor="#1C1E23" fontWeight="400" align="center">
          {UIStrings.UPGRADE_SUBTEXT}
        </Text>
        <Button
          color="indigo.500"
          lineHeight="20px" 
          fontSize="sm"
          variant="link"
          onClick={() => {    
              window.location.replace(`https://${url}`);
          }}
        >
          {UIStrings.BUTTON_TEXT.NEW_ACCOUNT}
        </Button>
      </Stack>
    </Background>
  );
}