/* eslint-disable react/require-default-props */
import {
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Button,
  Text,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  InputRightAddon,
  SimpleGrid,
  GridItem,
  Wrap,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { ViewOffIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import { UserDataType } from '../utils/types/formValues.type';
import NavigationPages from '../utils/constants/NavigationPages';
import UIStrings from '../utils/constants/uIStrings';
import Eye from '../assets/images/eye';

export type ISignInSignupForm = {
  formLabel1: string;
  formLabel2: string;
  isUnderParsec?: boolean | undefined;
  subDomain?: string;
  showForgotPasswordLink: boolean;
  showCheckbox: boolean;
  onCheckBoxClicked?: (clicked: boolean) => void;
  checkboxLabel?: string;
  buttonLabel: string;
  isLoading: boolean;
  validateFormEntry: (event: React.ChangeEvent<HTMLInputElement>) => void;
  userData: UserDataType;
  handleClick: () => void;
  isSignUp: boolean;
  onFormLabel1Focus: () => void;
};

export default function SignInSignupForm({
  formLabel1,
  formLabel2,
  isUnderParsec = false,
  subDomain,
  showForgotPasswordLink,
  showCheckbox,
  onCheckBoxClicked,
  checkboxLabel = '',
  buttonLabel,
  isLoading,
  validateFormEntry,
  userData,
  handleClick,
  onFormLabel1Focus,
  isSignUp,
}: ISignInSignupForm) {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const { DOMAIN, FORGOT_ALIAS, FORGOT_PASSWORD } = UIStrings;

  const handleShowPassword = () => {
    setShowPassword((showPasswordValue) => !showPasswordValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <>
      <SimpleGrid rowGap={4} w="full" maxW="lg">
        {isUnderParsec && (
          <GridItem>
            <FormControl id="domain" pb={1} isInvalid={userData.domain?.error} isRequired>
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                align="start"
                justify="space-between"
              >
                <FormLabel textColor={userData.domain?.error ? 'red.500' : 'gray.700'}>
                  {DOMAIN}
                </FormLabel>

                <Button
                  lineHeight="20px"
                  bottom={[3, 0, 0]}
                  variant="link"
                  onClick={() => history.push(NavigationPages.DOMAIN_RECOVERY)}
                >
                  {FORGOT_ALIAS}
                </Button>
              </Stack>
              <InputGroup>
                <Input
                  type="text"
                  borderRadius="4px"
                  fontSize="sm"
                  maxW="md"
                  bg="white"
                  data-cy="inputDomain"
                  onChange={validateFormEntry}
                  onFocus={onFormLabel1Focus}
                  focusBorderColor={userData.domain?.error ? 'red.500' : 'blue.500'}
                />
                {subDomain && <InputRightAddon children={subDomain} />}
              </InputGroup>
              {userData.domain?.error && (
                <FormErrorMessage fontSize="sm" fontWeight="400" lineHeight="20px">
                  {userData.domain?.helperText}
                </FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
        )}

        <GridItem>
          <FormControl id="email" pb={1} isInvalid={userData.email.error} isRequired>
            <FormLabel textColor={userData.email.error ? 'red.500' : 'gray.700'}>
              {formLabel1}
            </FormLabel>
            <Input
              type="email"
              borderRadius="4px"
              fontSize="sm"
              maxW="md"
              data-cy="inputEmail"
              bg="white"
              onChange={validateFormEntry}
              onFocus={onFormLabel1Focus}
              focusBorderColor={userData.email.error ? 'red.500' : 'blue.500'}
            />
            {userData.email.error && (
              <FormErrorMessage fontSize="sm" fontWeight="400" lineHeight="20px">
                {userData.email.helperText}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl
            id="password"
            pb={0.75}
            isInvalid={isSignUp && userData.password.error}
            isRequired
          >
            <Stack direction={{ base: 'column', sm: 'row' }} align="start" justify="space-between">
              <FormLabel textColor={isSignUp && userData.password.error ? 'red.500' : 'gray.700'}>
                {formLabel2}
              </FormLabel>
              {showForgotPasswordLink && (
                <Button
                  lineHeight="20px"
                  bottom={[3, 0, 0]}
                  variant="link"
                  data-cy="forgotPassBtn"
                  onClick={() => history.push(NavigationPages.FORGOT_PASSWORD)}
                >
                  {FORGOT_PASSWORD}
                </Button>
              )}
            </Stack>
            <InputGroup size="md">
              <Input
                bg="white"
                borderRadius="4px"
                fontSize="sm"
                type={showPassword ? 'text' : 'password'}
                placeholder="••••••••••••"
                data-cy="inputPassword"
                onChange={validateFormEntry}
                onFocus={onFormLabel1Focus}
                onKeyDown={handleKeyDown}
                focusBorderColor={isSignUp && userData.password.error ? 'red.500' : 'blue.500'}
              />
              <InputRightElement>
                {!showPassword ? (
                  <Eye w="16px" h="16px" onClick={handleShowPassword} />
                ) : (
                  <ViewOffIcon color="gray.300" onClick={handleShowPassword} w="16px" h="16px" />
                )}
              </InputRightElement>
            </InputGroup>
            {userData.password.error && isSignUp && (
              <FormErrorMessage fontSize="sm" fontWeight="400" lineHeight="20px">
                {userData.password.helperText}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
      </SimpleGrid>
      <Stack spacing={5} pt={5}>
        {/* Commented remember me as backend/c8db is not sending token with proper expiration time. */}
        {/* {showCheckbox && (
          <Checkbox
            fontSize="sm"
            lineHeight="20px"
            fontWeight="400"
            pb={2}
            onChange={(e) => (onCheckBoxClicked ? onCheckBoxClicked(e.target.checked) : {})}
          >
            {checkboxLabel}
          </Checkbox>
        )} */}
        {!showCheckbox && (
          <Wrap spacing="1">
            <Text variant="subText">By signing up, you accept our</Text>
            <Button
              variant="link"
              data-cy="termsFormBtn"
              onClick={() => window.open('https://www.macrometa.com/terms-of-service')}
            >
              terms of service
            </Button>
            <Text variant="subText">and</Text>
            <Text variant="subText">have</Text>
            <Text variant="subText">read</Text>
            <Text variant="subText">our</Text>
            <Button
              variant="link"
              ml="-1.5"
              data-cy="privacyFormBtn"
              onClick={() => window.open('https://www.macrometa.com/privacy-policy')}
            >
              privacy policy.
            </Button>
          </Wrap>
        )}
        <Button
          id="actionBtn"
          isLoading={isLoading}
          spinnerPlacement="start"
          variant="primary"
          _hover={{
            bg: 'indigo.600',
          }}
          isDisabled={isLoading}
          borderRadius={!showCheckbox ? '1.5rem' : '4px'}
          height={!showCheckbox ? '3rem' : '2.5rem'}
          onClick={handleClick}
          fontSize={!showCheckbox ? 'lg' : 'sd'}
          data-cy="submitBtn"
        >
          {buttonLabel}
        </Button>
      </Stack>
    </>
  );
}
