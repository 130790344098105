import { Stack, Text, Button } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import UIStrings from '../utils/constants/uIStrings';
import Background from './Background';

export default function Success() {
  const history = useHistory();
  return (
    <Background showButton={false}>
      <Stack
        spacing={6}
        w={['', 'full']}
        maxW="md"
        bg="white"
        boxShadow="lg"
        borderRadius="12px"
        p={6}
        mt={['40', '60']}
        alignItems="center"
      >
        <Text lineHeight="28px" fontSize="24px" textColor="#1C1E23">
          {UIStrings.ALL_SET}
        </Text>
        <Button
          color="indigo.500"
          variant="link"
          onClick={() => {
            history.push('/');
          }}
        >
          {UIStrings.BUTTON_TEXT.LOG_IN}
        </Button>
      </Stack>
    </Background>
  );
}
