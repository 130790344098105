import { Box, Heading, Stack } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AlertMessage from '../components/AlertMessage';
import Background from '../components/Background';
import DomainEntry from '../components/DomainEntry';
import SignUpMaintenanceContext from '../hooks/useSignUpMaintenanceContext';
import NavigationPages from '../utils/constants/NavigationPages';
import UIStrings from '../utils/constants/uIStrings';
import { isUrlAccessible, navigateToUrl } from '../utils/helper/navigator';
import { DomainDataType, InfoDataType } from '../utils/types/formValues.type';
import { SignUpMaintenanceContextType } from '../utils/types/maintenanceContext.type';

export default function Domain() {
  //#region states and context
  const { parsecDomain } = useContext<SignUpMaintenanceContextType>(SignUpMaintenanceContext);
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [displayAlert, setDisplayAlert] = useState<'none' | 'flex'>('none');
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [domainData, setDomainData] = useState<DomainDataType>({
    domain: {
      value: '',
      error: false,
      helperText: '',
    },
  });
  const {
    VALIDATION_TEXT: { USER_ALREADY_SIGNED_UP, INVALID_DOMAIN },
    BUTTON_TEXT: { SIGN_UP, LOG_IN },
    ERROR_TEXTS: { DOMAIN_NOT_AVAILABLE },
  } = UIStrings;

  //#endregion

  //#region effects
  useEffect(() => {
    if (location.state) {
      const infoData = location.state as InfoDataType;
      if (infoData) {
        if (infoData.userAlreadyExist) {
          toggleAlert('flex', USER_ALREADY_SIGNED_UP);
        } else if (infoData.isServerError) {
          toggleAlert('flex', infoData.errorMessage);
        }
      }
    }
  }, [location.state]);
  //#endregion
  //#region validations
  const toggleAlert = (alertMode: 'none' | 'flex', message: string) => {
    setAlertMessage(message);
    setDisplayAlert(alertMode);
  };

  const validateUrlAccessibiltyAndRedirect = async (url: string) => {
    const isAccessible = await isUrlAccessible(url);
    if (isAccessible) {
      navigateToUrl(url);
    } else {
      toggleAlert('flex', DOMAIN_NOT_AVAILABLE);
    }
  };

  const validateDomainText = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const { value } = event.target;
    processValidationsForDomain(value);
  };

  const processValidationsForDomain = (value: string) => {
    if (!value.trim()) {
      populateDomainData('', INVALID_DOMAIN, true);
    } else {
      populateDomainData(value, '', false);
    }
  };

  const populateDomainData = (value: string, validationText: string, isError: boolean) => {
    setDomainData((domainDataObject) => ({
      ...domainDataObject,
      domain: {
        error: isError,
        helperText: validationText,
        value,
      },
    }));
  };
  //#endregion

  //#region Event Handlers
  const onDomainEntryFocus = () => {
    toggleAlert('none', '');
  };

  const handleOnAlertClose = () => {
    toggleAlert('none', '');
  };

  const handleNext = async () => {
    setLoading(true);
    if (domainData.domain.error || domainData.domain.value.length === 0) {
      processValidationsForDomain(domainData.domain.value);
    } else {
      await validateUrlAccessibiltyAndRedirect(`${domainData.domain.value}.${parsecDomain}`);
    }
    setLoading(false);
  };
  //#endregion

  //#region UI
  return (
    <Box>
      <Background buttonText={SIGN_UP} navigationLink={NavigationPages.SIGN_UP}>
        <Stack
          spacing={6}
          w={['', 'full']}
          maxW="md"
          bg="white"
          boxShadow="lg"
          borderRadius="0.75rem"
          p={6}
          mb={['20', '0']}
          mt={['40', '60']}
        >
          <Box rounded="lg" maxW="md" bg="white">
            <Stack align="start" pb="13px">
              <Heading fontSize="2xl" fontStyle="normal" fontWeight="700" lineHeight="28px">
                {LOG_IN}
              </Heading>
            </Stack>
            <AlertMessage
              alertType="error"
              alertMessage={alertMessage}
              displayAlert={displayAlert}
              onClose={handleOnAlertClose}
              isOpen
            />
            <DomainEntry
              validateDomainText={validateDomainText}
              isLoading={loading}
              domainData={domainData}
              handleNext={handleNext}
              subDomain={`.${parsecDomain}`}
              onDomainEntryFocus={onDomainEntryFocus}
            ></DomainEntry>
          </Box>
        </Stack>
      </Background>
    </Box>
  );
  //#endregion
}
