import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SignUpMaintenanceContext from '../hooks/useSignUpMaintenanceContext';
import NavigationPages from '../utils/constants/NavigationPages';
import { sanitizeUrl } from '../utils/helper/helperFunctions';
import { SignUpMaintenanceContextType } from '../utils/types/maintenanceContext.type';

export default function SignUpBeta() {
  const history = useHistory();
  const { signUpMaintainanceLink } =
    useContext<SignUpMaintenanceContextType>(SignUpMaintenanceContext);

  useEffect(() => {
    if (signUpMaintainanceLink) {
      window.location.replace(signUpMaintainanceLink);
      return;
    }
    history.push(NavigationPages.ERROR);
  }, []);

  return <> </>;
}
