import axios from 'axios';
import ApiCallMethods from '../constants/api';

const fetchUsingAxios = async (method: ApiCallMethods, url: string, data?: any) => {
  let response;
  switch (method) {
    case ApiCallMethods.GET:
      response = await axios.get(url);
      break;
    case ApiCallMethods.POST:
      response = await axios.post(url, data);
      break;
    default:
      response = null;
      break;
  }

  return response;
};

export default fetchUsingAxios;
