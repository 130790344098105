import { Box, Stack, Heading } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3';
import useApi from '../hooks/useApi';
import ApiCallMethods from '../utils/constants/api';
import ApiEndPoints from '../utils/constants/endPoints';
import NavigationPages from '../utils/constants/NavigationPages';
import { emailRegex } from '../utils/helper/helperFunctions';
import { LoginReqType } from '../utils/types/apiRequest.type';
import {
  InfoDataType,
  ReVerifyUserDataType,
  RouteDataType,
  UpgradeScaleDataType,
  UserDataType,
} from '../utils/types/formValues.type';
import AlertMessage from '../components/AlertMessage';
import Background from '../components/Background';
import Loader from '../components/Loader';
import SignInSignupForm from '../components/SignInSignupForm';
import UIStrings, { NavigationWorkFlowEnum } from '../utils/constants/uIStrings';
import SignUpMaintenanceContext from '../hooks/useSignUpMaintenanceContext';
import { SignUpMaintenanceContextType } from '../utils/types/maintenanceContext.type';
import { ApiResponse } from '../utils/types/apiResponse.type';
import fetchUsingAxios from '../utils/service/api';
import DomainMailStatus from '../components/DomainMailStatus';
import { handleSuccessfulLoginAndRedirect } from '../utils/helper/loginHelper';
import { navigateToUrl } from '../utils/helper/navigator';

export default function Login() {
  // #region State Initialisations

  const { isUnderMaintenance, isConfigError, isLoading, isPrivateGdn } =
    useContext<SignUpMaintenanceContextType>(SignUpMaintenanceContext);

  const location = useLocation();
  const history = useHistory();
  const { response, getApiResult } = useApi();
  const [loading, setLoading] = useState<boolean>(false);
  const [privateGdn, setPrivateGdn] = useState<boolean | undefined>(false);
  const [displayAlert, setDisplayAlert] = useState<'none' | 'flex'>('none');
  const [token, setToken] = useState<string | undefined>();
  const [showError, setShowError] = useState<boolean>(false);
  const [rememberMeChecked, setRememberMeChecked] = useState<boolean>(false);
  const [errorResponse, setErrorResponse] = useState<string>('');
  const [userData, setUserData] = useState<UserDataType>({
    email: {
      value: '',
      error: false,
      alreadyValidated: false,
      helperText: '',
    },
    password: {
      value: '',
      error: false,
      alreadyValidated: false,
      helperText: '',
    },
  });

  // #endregion

  // #region Effects

  useEffect(() => {
    loadReCaptcha(import.meta.env.VITE_GOOGLE_CAPTCHA_V3_SITE_KEY);
  }, []);

  useEffect(() => {
    if (location.state) {
      const infoData = location.state as InfoDataType;
      if (infoData) {
        if (infoData.userAlreadyExist) {
          setShowError(true);
          setErrorResponse(UIStrings.VALIDATION_TEXT.USER_ALREADY_SIGNED_UP);
          setDisplayAlert('flex');
        } else if (infoData.isServerError) {
          setShowError(true);
          setErrorResponse(infoData.errorMessage);
          setDisplayAlert('flex');
        }
      }
    }
  }, [location.state]);

  useEffect(() => {
    setPrivateGdn(isPrivateGdn);
  }, [isPrivateGdn]);

  useEffect(() => {
    if (isConfigError) {
      history.push(NavigationPages.MAINTENANCE);
    }
  }, [isConfigError, history]);

  useEffect(() => {
    if (response) {
      if (response?.isSuccess) {
        handleSuccessfulLogin(response);
      } else {
        if (response?.status === 302) {
          const routeData: RouteDataType = {
            userDataObject: userData,
            rememberMe: rememberMeChecked,
            redirectedFromLogin: true,
            redirectedFromSignup: false,
            redirectedFromPasswordReset: false,
            redirectedFromReverification: false,
          };
          history.replace({ pathname: NavigationPages.RECAPTCHA, state: routeData });
          return;
        }
        if (response?.status === 409) {
          const reVerifyInfoData: ReVerifyUserDataType = {
            email: userData.email.value,
            error: UIStrings.VALIDATION_TEXT.UNVERIFIED_USER_EXIST,
            areAttemptsExhausted: false,
          };
          history.push({ pathname: NavigationPages.RE_VERIFICATION, state: reVerifyInfoData });
          return;
        }
        setDisplayAlert('flex');
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);
  // #endregion

  const handleSuccessfulLogin = (loginResponse: ApiResponse) => {
    const workflowToRun = handleSuccessfulLoginAndRedirect(
      loginResponse,
      rememberMeChecked.toString(),
    );

    if (workflowToRun === NavigationWorkFlowEnum.PRIVATE_GDN) {
      const { privateGdnUrl, isPrivateGdn } = loginResponse.data;
      handlePrivateGDNUrlRedirection(privateGdnUrl, isPrivateGdn);
    }
  };

  const handlePrivateGDNUrlRedirection = (privateGdnUrl: string, isPrivateGdn: boolean) => {
    if (!isPrivateGdn) {
      const upgradeScaleDataType: UpgradeScaleDataType = {
        urlToNavigate: privateGdnUrl,
      };
      history.replace({ pathname: NavigationPages.UPGRADE_SCALE, state: upgradeScaleDataType });
      return;
    }
    navigateToUrl(privateGdnUrl);
  };
  // #region ApiCall
  const handleLogin = async () => {
    setShowError(false);
    setErrorResponse('');
    const { email, password } = userData;
    //check if user has entered a valid email and password
    if (checkIsEmailAndPassProvided()) {
      setLoading(true);
      const loginRequest: LoginReqType = {
        username: userData.email.value,
        password: userData.password.value,
        rememberMe: rememberMeChecked,
        verifyToken: token,
        secret: import.meta.env.VITE_GOOGLE_CAPTCHA_V3_SECRET_KEY,
      };

      await getApiResult(ApiCallMethods.POST, ApiEndPoints.LOGIN, loginRequest);
    }
    checkIsValidEmail(email.value);
    checkIsValidPassword(password.value);
  };

  const rememberMeClicked = (checkboxChecked: boolean) => {
    setRememberMeChecked(checkboxChecked);
  };
  // #endregion

  // #region Validations

  const validateFormEntry = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const { id, value } = event.target;
    switch (id) {
      case 'email':
        checkIsValidEmail(value);
        break;
      case 'password':
        checkIsValidPassword(value);
        break;
      default:
        break;
    }
  };

  const checkIsValidEmail = (value: string) => {
    if (!value.trim()) {
      setUserData((userDataObject) => ({
        ...userDataObject,
        email: {
          error: true,
          helperText: UIStrings.VALIDATION_TEXT.VALID_EMAIL,
          value,
          alreadyValidated: false,
        },
      }));
    } else if (value.trim() && !emailRegex.test(value)) {
      setUserData((userDataObject) => ({
        ...userDataObject,
        email: {
          error: true,
          helperText: UIStrings.VALIDATION_TEXT.VALID_EMAIL,
          value,
          alreadyValidated: false,
        },
      }));
    } else {
      setUserData((userDataObject) => ({
        ...userDataObject,
        email: {
          error: false,
          alreadyValidated: true,
          helperText: '',
          value,
        },
      }));
    }
  };

  const checkIsValidPassword = (value: string) => {
    if (!value.trim()) {
      setUserData((userDataObject) => ({
        ...userDataObject,
        password: {
          error: true,
          helperText: UIStrings.VALIDATION_TEXT.VALID_PASSWORD,
          value,
          alreadyValidated: false,
        },
      }));
    } else {
      setUserData((userDataObject) => ({
        ...userDataObject,
        password: {
          error: false,
          helperText: '',
          alreadyValidated: true,
          value,
        },
      }));
    }
  };

  const checkIsEmailAndPassProvided = (): boolean => {
    if (
      userData.email.error ||
      userData.password.error ||
      userData.email.value.length === 0 ||
      userData.password.value.length === 0
    ) {
      return false;
    }
    return true;
  };

  // #endregion

  // #region Event Handlers
  const handleOnClose = () => {
    setDisplayAlert('none');
  };

  const onFormLabel1Focus = () => {
    setDisplayAlert('none');
  };

  // #endregion

  if (isLoading) {
    return <Loader />;
  }

  const verifyCallback = (reCaptchaToken: string) => {
    setToken(reCaptchaToken);
  };
  return (
    <Background
      showButton={!privateGdn}
      headerLabel={UIStrings.BACKGROUND_HEADER}
      buttonText={UIStrings.BUTTON_TEXT.SIGN_UP}
      navigationLink={NavigationPages.SIGN_UP}
    >
      <Stack
        spacing={6}
        w={['', 'full']}
        maxW="md"
        bg="white"
        boxShadow="lg"
        borderRadius="12px"
        p={6}
        mb={['20', '0']}
        mt={['40', '60']}
      >
        <Box rounded="lg" maxW="md" bg="white">
          <Stack align="start" pb="13px">
            <Heading fontSize="21px" fontStyle="normal" fontWeight="700" lineHeight="28px">
              Log in
            </Heading>
          </Stack>
          {response?.isSuccess === false && (
            <AlertMessage
              alertType="error"
              alertMessage={response?.data?.message?.toString()}
              displayAlert={displayAlert}
              onClose={handleOnClose}
              isOpen={response?.isSuccess === false}
            />
          )}
          {showError && (
            <AlertMessage
              alertType="error"
              alertMessage={errorResponse}
              displayAlert={displayAlert}
              onClose={handleOnClose}
              isOpen
            />
          )}
          <SignInSignupForm
            formLabel1={UIStrings.EMAIL_ADDRESS}
            formLabel2={UIStrings.PASSWORD}
            isUnderParsec={false}
            validateFormEntry={validateFormEntry}
            handleClick={handleLogin}
            isLoading={loading}
            showForgotPasswordLink
            showCheckbox
            onCheckBoxClicked={rememberMeClicked}
            userData={userData}
            checkboxLabel={UIStrings.REMEMBER_ME}
            buttonLabel={UIStrings.BUTTON_TEXT.LOG_IN}
            isSignUp={false}
            onFormLabel1Focus={onFormLabel1Focus}
          />
        </Box>
      </Stack>
      <ReCaptcha
        sitekey={import.meta.env.VITE_GOOGLE_CAPTCHA_V3_SITE_KEY}
        action="login"
        verifyCallback={verifyCallback}
      />
    </Background>
  );
}
