import { Stack, Text, Button, Spinner } from '@chakra-ui/react';
import axios from 'axios';
import { parse } from 'query-string';
import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Background from '../components/Background';
import ErrorPage from '../components/Error';
import Loader from '../components/Loader';
import useApi from '../hooks/useApi';
import ApiCallMethods from '../utils/constants/api';
import ApiEndPoints from '../utils/constants/endPoints';
import NavigationPages from '../utils/constants/NavigationPages';
import UIStrings from '../utils/constants/uIStrings';
import { handleGlobalUrlRedirection, navigateToFed } from '../utils/helper/navigator';
import { setCookieValue } from '../utils/helper/persist';
import fetchUsingAxios from '../utils/service/api';
import { ReVerifyUserDataType } from '../utils/types/formValues.type';

export default function Verify() {
  const location = useLocation();
  const history = useHistory();
  const [showError, setShowError] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(false);
  const { response, getApiResult } = useApi();
  const [tenantName, setTenantName] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const verifyEmail = async (token: string | (string | null)[]) => {
    setLoading(true);
    await getApiResult(ApiCallMethods.GET, `${ApiEndPoints.VERIFY_SIGN_UP_EMAIL}${token}`);
    setLoading(false);
  };

  useEffect(() => {
    try {
      const { token } = parse(location.search);
      if (token) {
        verifyEmail(token);
      } else {
        history.push('/');
      }
    } catch {
      setShowError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (response) {
      if (response?.isSuccess) {
        if (response.data) {
          const { userJWT, age } = response.data;
          setCookieValue(UIStrings.SESSION_CONSTANTS.ACCESS_TOKEN, userJWT, age);

          const { tenant } = response.data.tenantName;

          setTenantName(tenant);
        }
      } else {
        if (response?.status === 401) {
          const userEmail = response.data.message;
          const reVerifyInfoData: ReVerifyUserDataType = {
            email: userEmail,
            error: UIStrings.VALIDATION_TEXT.VERIFY_TOKEN_EXPIRED,
            areAttemptsExhausted: false,
          };
          history.replace({ pathname: NavigationPages.RE_VERIFICATION, state: reVerifyInfoData });
          return;
        }
        history.push(NavigationPages.ERROR);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const checkIfGlobalUrlCreationIsEnabled = async () => {
    try {
      const isGlobalUrlCreationEnabled = await fetchUsingAxios(
        ApiCallMethods.GET,
        `${ApiEndPoints.GET_GLOBAL_URL_DETAILS}`,
      );
      if (isGlobalUrlCreationEnabled) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (tenantName) {
      (async () => {
        const isGlobalUrlEnabled = await checkIfGlobalUrlCreationIsEnabled();
        if (isGlobalUrlEnabled) {
          const intervalId = setInterval(async () => {
            try {
              const tenantDetails = await fetchUsingAxios(
                ApiCallMethods.GET,
                `${ApiEndPoints.TENANT_DETAILS}?tenant=${tenantName}`,
              );
              if (tenantDetails) {
                const { isDnsInfoFound, isStatusEnabled, globalURL } = tenantDetails.data;
                handleGlobalUrlRedirection(globalURL, isDnsInfoFound, isStatusEnabled);
              }
            } catch (error) {
              if (axios.isAxiosError(error) && error.response?.status !== 400) {
                navigateToFed(true);
              }
            }
          }, 10000);

          return () => clearInterval(intervalId);
        } else {
          navigateToFed(true);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantName]);

  if (showError) {
    return <ErrorPage />;
  }

  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <Background buttonText={UIStrings.BUTTON_TEXT.LOG_IN} navigationLink={NavigationPages.LOGIN}>
        <Stack
          spacing={6}
          w={['', 'full']}
          maxW="md"
          bg="white"
          boxShadow="lg"
          borderRadius="12px"
          p={6}
          mt={['40', '60']}
          alignItems="center"
        >
          <Text
            lineHeight="28px"
            fontSize="21px"
            fontWeight={600}
            textColor="#1C1E23"
            textAlign="center"
          >
            {UIStrings.SETTING_ACCOUNT}
          </Text>
          <Spinner color="positive" left="50%" top="50%" size="lg" />
          <Button
            variant="link"
            color="indigo.500"
            fontWeight={400}
            onClick={() => window.open(UIStrings.STATIC_URLS.TROUBLESHOOT)}
          >
            {UIStrings.BUTTON_TEXT.TROUBLESHOOT}
          </Button>
        </Stack>
      </Background>
    </div>
  );
}
