import { Heading, Stack } from '@chakra-ui/react';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useApi from '../hooks/useApi';
import ApiCallMethods from '../utils/constants/api';
import NavigationPages from '../utils/constants/NavigationPages';
import { emailRegex } from '../utils/helper/helperFunctions';
import { ForgotPasswordReqType } from '../utils/types/apiRequest.type';
import { InfoDataType, RouteDataType, UserDataType } from '../utils/types/formValues.type';
import AlertMessage from '../components/AlertMessage';
import Background from '../components/Background';
import ForgotResetForm from '../components/ForgotResetForm';
import MailStatus from '../components/MailStatus';
import ApiEndPoints from '../utils/constants/endPoints';
import UIStrings from '../utils/constants/uIStrings';
import { SignUpMaintenanceContextType } from '../utils/types/maintenanceContext.type';
import SignUpMaintenanceContext from '../hooks/useSignUpMaintenanceContext';

export default function ForgotPassword() {
  const { response, getApiResult } = useApi();

  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const [showError, setShowError] = useState<boolean>(false);
  const [errorResponse, setErrorResponse] = useState<string>('');
  const [mailSentSuccess, setMailSentSuccess] = useState<boolean>();
  const history = useHistory();
  const [displayAlert, setDisplayAlert] = useState<'none' | 'flex'>('none');
  const [token, setToken] = useState<string | undefined>();
  const [userData, setUserData] = useState<UserDataType>({
    email: {
      value: '',
      error: false,
      alreadyValidated: false,
      helperText: '',
    },
    password: {
      value: '',
      error: false,
      alreadyValidated: false,
      helperText: '',
    },
  });
  useEffect(() => {
    if (location.state) {
      const infoData = location.state as InfoDataType;
      if (infoData) {
        if (infoData.resetPasswordSuccess) {
          setMailSentSuccess(true);
        } else if (infoData.isServerError) {
          setShowError(true);
          setErrorResponse(infoData.errorMessage);
          setDisplayAlert('flex');
        }
      }
    }
    loadReCaptcha(import.meta.env.VITE_GOOGLE_CAPTCHA_V3_SITE_KEY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (response) {
      if (response?.isSuccess) {
        setDisplayAlert('none');
        setMailSentSuccess(true);
      } else {
        if (response?.status === 409) {
          setMailSentSuccess(true);
          history.replace('/');
          return;
        }
        if (response?.status === 302) {
          const routeData: RouteDataType = {
            userDataObject: userData,
            rememberMe: false,
            redirectedFromLogin: false,
            redirectedFromSignup: false,
            redirectedFromPasswordReset: true,
            redirectedFromReverification: false,
          };

          history.replace({ pathname: NavigationPages.RECAPTCHA, state: routeData });
        }
        if (response?.status === 429) {
          setDisplayAlert('flex');
          return;
        }
        setDisplayAlert('flex');
      }
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, response]);

  const validateEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    if (event.target.id === 'email') {
      if (!emailRegex.test(event.target.value)) {
        setUserData((userDataObject) => ({
          ...userDataObject,
          email: {
            error: true,
            helperText: 'Enter Valid Email',
            value: event.target.value,
            alreadyValidated: false,
          },
        }));
      } else {
        setUserData((userDataObj) => ({
          ...userDataObj,
          email: {
            error: false,
            alreadyValidated: true,
            helperText: '',
            value: event.target.value,
          },
        }));
      }
    }
  };

  const handleBlur = () => {
    if (userData.email.value.length === 0) {
      setUserData((userDataObj) => ({
        ...userDataObj,
        email: {
          error: false,
          alreadyValidated: true,
          helperText: '',
          value: '',
        },
      }));
    }
  };

  const handleResetPassword = () => {
    setLoading(true);
    const forgotPasswordRequest: ForgotPasswordReqType = {
      email: userData.email.value,
      verifyToken: token,
      secret: import.meta.env.VITE_GOOGLE_CAPTCHA_V3_SECRET_KEY,
    };

    let endpoint = ApiEndPoints.FORGOT_PASSWORD;

    getApiResult(ApiCallMethods.POST, endpoint, forgotPasswordRequest);
  };

  const handleOnClose = () => {
    setDisplayAlert('none');
  };

  const onFormLabel1Focus = () => {
    setDisplayAlert('none');
  };

  const verifyCallback = (reCaptchaToken: string) => {
    setToken(reCaptchaToken);
  };

  return (
    <div>
      {!mailSentSuccess ? (
        <Background
          buttonText={UIStrings.BUTTON_TEXT.LOG_IN}
          navigationLink={NavigationPages.LOGIN}
        >
          <Stack
            w={['', 'full']}
            maxW="md"
            bg="white"
            boxShadow="lg"
            borderRadius="12px"
            p={6}
            mt={['40', '60']}
          >
            <Stack align="start" pb={3}>
              <Heading
                fontSize="21px"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="28px"
                textColor="#1C1E23"
              >
                {UIStrings.PASSWORD_RESET}
              </Heading>
            </Stack>
            {response?.isSuccess === false && (
              <AlertMessage
                alertType="error"
                alertMessage={response?.data?.message?.toString()}
                displayAlert={displayAlert}
                onClose={handleOnClose}
                isOpen={response?.isSuccess === false}
              />
            )}
            {showError && (
              <AlertMessage
                alertType="error"
                alertMessage={errorResponse}
                displayAlert={displayAlert}
                onClose={handleOnClose}
                isOpen
              />
            )}
            <ForgotResetForm
              formLabel1={UIStrings.EMAIL_ADDRESS}
              formLabel1PlaceHolder=""
              formLabel1Id="email"
              formLabel1error={userData.email.error}
              formLabel1helperText={userData.email.helperText}
              formLabel2={UIStrings.PASSWORD}
              formLabel2error={false}
              inpuType="email"
              formLabel2helperText=""
              disableShowPassword={true}
              isLoading={loading}
              onFormLabel1Focus={onFormLabel1Focus}
              showformLabel2={false}
              onFormLabel2Blur={() => {}}
              validateEmailPassword={validateEmail}
              handleClick={handleResetPassword}
              buttonLabel={UIStrings.BUTTON_TEXT.SUBMIT}
              onFormLabel1Blur={handleBlur}
              buttonDisabled={userData.email.error || userData.email.value.length === 0 || loading}
            />
          </Stack>
          <ReCaptcha
            sitekey={import.meta.env.VITE_GOOGLE_CAPTCHA_V3_SITE_KEY}
            action="resetPassword"
            verifyCallback={verifyCallback}
          />
        </Background>
      ) : (
        <MailStatus handleClick={() => setMailSentSuccess(false)} />
      )}
    </div>
  );
}
