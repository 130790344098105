const HOST_URL = `https://${window.location.hostname}/_api/auth`;

const PARSEC_HOST_URL = 'https://parsecmanager.eng.macrometa.io/parsec';

const ApiEndPoints = {
  FORGOT_PASSWORD: `${HOST_URL}/reset-password`,
  CHANGE_PASSWORD: `${HOST_URL}/reset-password/verify?token=`,
  LOGIN: `${HOST_URL}/login`,
  SIGN_UP: `${HOST_URL}/sign-up`,
  VERIFY_SIGN_UP_EMAIL: `${HOST_URL}/sign-up/verify?token=`,
  RESEND_VERIFICATION_MAIL: `${HOST_URL}/sign-up/resend-verfication-link`,
  VERIFY_EMAIL: `${HOST_URL}/update-tenant-email/verify-email-update?token=`,
  FRONTEND_CONFIG: `${HOST_URL}/sign-up/frontend-config`,
  TENANT_DETAILS: `${HOST_URL}/sign-up/tenant-details`,
  INVITE_USER: `${HOST_URL}/invite-user`,
  CREATE_USER: `${HOST_URL}/invite-user/create-user`,
  GET_GLOBAL_URL_DETAILS: `${HOST_URL}/sign-up/global-url-details`,
  GET_ALIASES: `${PARSEC_HOST_URL}/fabrics/email?email=`,
  FORGOT_ALIAS: `${PARSEC_HOST_URL}/fabrics/getAliasByEmail?email=`,
  HEALTHZ: `https://${window.location.hostname}/_healthz`,
  PLACEMENT_SUB_URL: '/userRegistrationURL',
  WRTParsec: {
    SIGN_UP: `_api/auth/sign-up`,
    RESEND_VERIFICATION: `_api/auth/sign-up/resend-verfication-link`,
  },
};

export const STRING_TO_REPLACE = {
  URL: 'url',
};

export const AccessibilityCheck = {
  COLLECTION_URL: `https://api-${STRING_TO_REPLACE.URL}/_fabric/_system/_api/collection`,
};

export default ApiEndPoints;
