import axios from 'axios';
import UIStrings, { CookiesAgeEnum } from '../constants/uIStrings';
import { AccessibilityCheck, STRING_TO_REPLACE } from '../constants/endPoints';
import { sanitizeUrl } from './helperFunctions';
import { setCookieValue } from './persist';

const checkURLContent = (domain: string) => {
  return window.location.hostname.includes(domain);
};

const checkIfGlobalUrlIsPopulated = async (globalUrl: string): Promise<boolean> => {
  try {
    const versionURL = `https://api-${globalUrl}/_fabric/_system/_api/version`;
    await axios.get(versionURL);
    return true;
  } catch (error) {
    if (axios.isAxiosError(error) && error.message !== 'Network Error') {
      return true;
    }
    return false;
  }
};

/* added mode : 'no-cors'
// this will refrain the js frontend client from reading the response nodes(opaque response) since cors is disabled.
// here, we just need to check the availability of the global url 
// and no specific nodes are to be read from response  */
const checkIfGlobalUrlUiIsPopulated = async (globalUrl: string): Promise<boolean> => {
  try {
    await fetch(`https://${globalUrl}`, { mode: 'no-cors' });
    return true;
  } catch (error) {
    if (axios.isAxiosError(error) && error.message !== 'Network Error') {
      return true;
    }
    return false;
  }
};

const checkGlobalUrlAccessibilityAndRedirect = async (
  globalUrl: string,
  rememberMe: boolean = false,
) => {
  try {
    const isGlobalUrlPopulated = await checkIfGlobalUrlIsPopulated(globalUrl);
    const isGlobalUrlUiPopulated = await checkIfGlobalUrlUiIsPopulated(globalUrl);

    if (isGlobalUrlPopulated && isGlobalUrlUiPopulated) {
      navigateToUrl(globalUrl);
      return;
    }

    navigateToFed(true, rememberMe);
  } catch (error) {
    navigateToFed(true, rememberMe);
  }
};

export const navigateToUrl = (urlToNavigate: string, sanitizeBeforeReplace: boolean = true) => {
  if (sanitizeBeforeReplace) {
    urlToNavigate = sanitizeUrl(urlToNavigate);
  }
  window.location.replace(`https://${urlToNavigate}`);
};

const retrieveHostUrl = (): string => {
  let hostName = '';

  switch (true) {
    case checkURLContent(UIStrings.URL_MAP.AUTH_ENG_URL):
      const redirectUrl = import.meta.env.VITE_DEFAULT_FEDERATION_URL;
      hostName = redirectUrl ? redirectUrl : UIStrings.URL_MAP.DEFAULT_FED;
      break;

    case checkURLContent(UIStrings.URL_MAP.AUTH_PAAS_URL):
      hostName = UIStrings.URL_MAP.GDN_URL;
      break;

    default:
      hostName = window.location.hostname;
  }
  return hostName;
};

export const navigateToFed = (setCookies = false, rememberMe = false) => {
  const hostName = retrieveHostUrl();

  if (setCookies) {
    setCookieValue(
      UIStrings.SESSION_CONSTANTS.HOST_URL,
      sanitizeUrl(hostName),
      rememberMe
        ? CookiesAgeEnum.REMEMBER_ME_COOKIES_AGE
        : CookiesAgeEnum.NON_REMEMBER_ME_COOKIES_AGE,
    );
  }

  navigateToUrl(hostName);
};

export const handleGlobalUrlRedirection = (
  globalURL: string,
  isDnsInfoFound: boolean,
  isStatusEnabled: boolean,
) => {
  if (globalURL && isDnsInfoFound && isStatusEnabled) {
    checkGlobalUrlAccessibilityAndRedirect(globalURL);
    return;
  }
  navigateToFed(true);
};

export const isUrlAccessible = async (url: string) => {
  try {
    const collectionUrl = AccessibilityCheck.COLLECTION_URL.replace(STRING_TO_REPLACE.URL, url);
    await axios.get(collectionUrl);
    return true;
  } catch (error) {
    if (axios.isAxiosError(error) && error.message !== 'Network Error') {
      return true;
    }
    return false;
  }
};
