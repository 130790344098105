import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import macrometaLightTheme from '../theme';

function ThemeProvider({ children }: { children: React.ReactNode }) {
  return (
    <ChakraProvider theme={macrometaLightTheme} resetCSS>
      {children}
    </ChakraProvider>
  );
}

export default ThemeProvider;
