import {
  createStandaloneToast,
  extendTheme,
  theme as chakraTheme,
  withDefaultColorScheme,
} from '@chakra-ui/react';
import { buttonStyles, formLabelStyles, linkStyles } from './components';
import inputStyles from './components/input';
import textStyles from './components/text';
import { macrometaLightThemeColors, colors } from './foundations/colors';
import typography from './foundations/typography';
import styles from './styles';

const overrides = {
  ...chakraTheme,
  ...typography,
  styles,
  components: {
    ...buttonStyles,
    ...linkStyles,
    ...formLabelStyles,
    ...textStyles,
    ...inputStyles,
  },
};

const macrometaLightTheme = extendTheme(
  {
    ...overrides,
    colors: {
      ...colors,
      ...macrometaLightThemeColors,
    },
  },
  withDefaultColorScheme({ colorScheme: 'primary' }),
);

// FIXME: update theme value based on current theme selection
export const toast = createStandaloneToast({ theme: macrometaLightTheme });

export default macrometaLightTheme;
